import React, { useState } from "react";
import { useParams } from "react-router-dom";


import Header from "../Shared/UI/Header";

function VerifyPage() {
  const { formId } = useParams();
  const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/forms/${formId}/verify`;

  const handleSubmit = (event) => {
    event.preventDefault();

    event.target.style = "display:none;";

    const verificationResultElement = document.getElementById(
      "verification-result"
    );

    verificationResultElement.innerHTML = `
      <div class="font-bold">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 animate-spin inline-block mr-2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
          />
        </svg> <span>Verifying PDF...</span>
      </div>
    `;

    const formData = new FormData(event.target);

    fetch(apiUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        verificationResultElement.innerHTML = `<div class="${
          data.verified ? "text-green-700" : "text-red-700"
        }">${data.message}</div>`;
      })
      .catch((error) => {
        // Handle the error
        verificationResultElement.innerHTML = `<div class="text-red-700">There was a problem verifying this document. Please try again later.</div>`;
        console.error(error);
      });
  };

  return (
    <div>
      <Header />
      <div className="my-8 container mx-auto prose">
        <h1 className="">Verify RE-Sure Sign PDF</h1>

        <div className="my-4" id="verification-result"></div>
        <form onSubmit={handleSubmit}>
          <p>
            Upload a pdf completed in RE-Sure sign to verify the document has
            not been modified since it was downloaded.
          </p>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="toVerify"
            >
              Select a PDF to Verify
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="toVerify"
              name="toVerify"
              type="file"
              accept=".pdf"
              required
            />
          </div>

          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Verify PDF
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default VerifyPage;
