import { useContext, useEffect } from "react";
import { pdfjs, Document } from "react-pdf";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import "jquery-ui/dist/themes/base/jquery-ui.css";
import "jquery-ui/dist/themes/base/theme.css";

import styles from "./PdfEditor.module.css";

import { FormContext } from "../Contexts/FormContext";
import ActionPanel from "./components/ActionPanel";
import PageWrapper from "./components/PageWrapper";
import DetailsPanel from "./components/DetailsPanel";
import SignButton from "./components/SignButton";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfEditor = (props) => {
  console.log("PdfEditor rendering");

  const ctx = useContext(FormContext);

  useEffect(() => {
    if (!localStorage.getItem("fetchingFormInfo")) {
      localStorage.setItem("fetchingFormInfo", "true");
      ctx.loadInitialForm();
      ctx.loadTracking({ formId: ctx.formId });
    }
  }, []);

  useEffect(() => {
    ctx.getEmailTemplates();
  }, []);

  const handleElementResizeEnd = (event, ui) => {
    let elementId = event.target.dataset?.elementId;
    let updatedElement = { ...ctx.elements.find((e) => e._id === elementId) };

    delete updatedElement._id;
    updatedElement.top = ui.position.top / ctx.scale;
    updatedElement.left = ui.position.left / ctx.scale;
    updatedElement.width = ui.size.width / ctx.scale;
    updatedElement.height = ui.size.height / ctx.scale;

    ctx.updateElement({ element: updatedElement, elementId });
  };

  const handleElementDropped = (event, ui) => {
    console.log({ event, ui });
    const droppedOn = event.target;
    const droppedOnRect = droppedOn.getBoundingClientRect();
    const droppedOnPage = +droppedOn.dataset?.pageNumber;

    const elementDropped = ui.draggable[0];
    const elementRect = elementDropped.getBoundingClientRect();
    const elementId = elementDropped.dataset?.elementId;
    const updatedElement = {
      page: droppedOnPage,
      top: (elementRect.top - droppedOnRect.top) / ctx.scale,
      left: ui.position.left / ctx.scale,
    };

    ctx.updateElement({ element: updatedElement, elementId });
  };

  const listenForClicksOffSelected = (event) => {
    // console.log("clicked", event.target);
    let element = document.querySelector(`.${styles.selected}`);
    // console.log(element);
    if (element) {
      if (!element.contains(event.target)) {
        // console.log("did not contain click");
        element.classList.remove(styles.selected);
        ctx.setSelectedElement(null);
      }
    }
  };

  const handleElementSelected = (event) => {
    const selected = event.target.closest(
      `.${styles.draggable},.${styles.resizable}`
    );
    if (!selected.classList.contains(`${styles.selected}`)) {
      $(`.${styles.selected}`).removeClass(`${styles.selected}`);
      $(`#${selected.id}`).addClass(`${styles.selected}`);

      ctx.setSelectedElement(selected.dataset.elementId);
    }
  };

  const handleRemoveElement = (event) => {
    const deleteHandle = event.target.closest(`.${styles["delete-handle"]}`);
    const removeId = deleteHandle.parentNode.dataset?.elementId;

    ctx.removeElement({ elementId: removeId });
  };

  const handleDuplicateElement = (event) => {
    const duplicateHandle = event.target.closest(
      `.${styles["duplicate-handle"]}`
    );
    const duplicateId = duplicateHandle.parentNode.dataset?.elementId;

    console.log("duplicate", duplicateId);

    const original = ctx.elements.find((e) => e._id === duplicateId);

    console.log({ original });
    const duplicate = {
      ...original,
      top: original.top + (original.height || 10) + 10,
    };

    delete duplicate._id;

    console.log({ duplicate });

    ctx.addElement({ element: duplicate });
  };

  useEffect(() => {
    console.log("setting up jquery drag/resize");
    if (!ctx.isSigned) {
      $(`.draggable,.${styles.draggable}`).draggable({
        handle: `.${styles["drag-handle"]}`,
        revert: "invalid",
      });

      $(`.resizable,.${styles.resizable}`).resizable({
        handles: "n, e, s, w",
        stop: handleElementResizeEnd,
      });

      $(`.droppable,.${styles.droppable}`).droppable({
        drop: handleElementDropped,
      });

      $(`.delete-handle,.${styles["delete-handle"]}`)
        .off("click")
        .on("click", handleRemoveElement);

      $(`.duplicate-handle,.${styles["duplicate-handle"]}`)
        .off("click")
        .on("click", handleDuplicateElement);

      $(`.draggable,.resizable,.${styles.draggable},.${styles.resizable}`)
        .off("click")
        .on("click", handleElementSelected);

      $(`.page-wrapper,.${styles["page-wrapper"]}`)
        .off("click")
        .on("click", listenForClicksOffSelected);
    }
  });

  const onDocumentLoadSuccess = ({ numPages }) => {
    ctx.setPdfPages(numPages);
  };

  return ctx.pdf ? (
    // <div id={styles["app-wrapper"]}>
    <div className="bg-[#efefef] flex">
      <ActionPanel styles={styles} />
      <div
        id={styles["pdf-viewer"]}
        // className={ctx.highlightFields ? "" : styles["remove-highlight"]}
      >
        {ctx.pages > 1 && <SignButton styles={styles} />}

        <Document file={ctx.pdf} onLoadSuccess={onDocumentLoadSuccess}>
          <PageWrapper styles={styles} />
        </Document>
      </div>
      {!ctx.isSigned && <DetailsPanel styles={styles} />}
    </div>
  ) : (
    "Loading..."
  );
};

export default PdfEditor;
