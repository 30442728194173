import { useState } from "react";
import TermsPrivacyFooter from "../Shared/UI/TermsPrivacyFooter";

export default function Login() {
  const [errorMessage, setErrorMessage] = useState(null);

  const search = new URLSearchParams(window.location.search);
  const code = search.get("code");
  const id = search.get("id");
  const redirect = search.get("redirect");

  if (code && id && !errorMessage) {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/user/resure/code?${search}`
    ).then((response) => {
      response.json().then((data) => {
        console.log(response.ok);
        console.log(data);

        if (!response.ok) {
          localStorage.removeItem("id_token");
          localStorage.removeItem("refresh_token");
          window.history.replaceState("", "/login");
          setErrorMessage(
            <div className="text-red-500 text-center">
              This code is no longer valid
            </div>
          );
        } else {
          setErrorMessage(null);
          localStorage.setItem("id_token", data.tokens.id_token);
          localStorage.setItem("refresh_token", data.tokens.refresh_token);
          if (redirect) {
            console.log({ redirect });
            window.location.href = redirect;
          } else {
            window.location.href = "/";
          }
        }
      });
    });
    return <div>Loading...</div>;
  }

  function handleLoginSubmit(event) {
    event.preventDefault();
    const loginInfo = Object.fromEntries(new FormData(event.target));

    fetch(`${process.env.REACT_APP_API_BASE_URL}/user/login`, {
      method: "post",
      body: JSON.stringify(loginInfo),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((data) => {
        console.log(response.ok);
        console.log(data);

        if (!response.ok) {
          localStorage.removeItem("id_token");
          localStorage.removeItem("refresh_token");
          setErrorMessage(
            <div className="text-red-500 text-center">
              Email or password is incorrect.
            </div>
          );
        } else {
          setErrorMessage(null);
          localStorage.setItem("id_token", data.tokens.id_token);
          localStorage.setItem("refresh_token", data.tokens.refresh_token);
          window.location.href = "/";
        }
      });
    });
  }

  return (
    <>
      {/*
          This example requires updating your template:
  
          ```
          <html class="h-full bg-white">
          <body class="h-full">
          ```
        */}
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto w-2/3"
            src="/images/resure-sign-logo-black.png"
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleLoginSubmit}>
            {errorMessage ? errorMessage : null}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                {/* <div className="text-sm">
                  <a
                    href="#"
                    className="font-semibold text-blue-600 hover:text-blue-500"
                  >
                    Forgot password?
                  </a>
                </div> */}
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Sign in
              </button>
            </div>
          </form>

          {/* <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{" "}
            <a
              href="#"
              className="font-semibold leading-6 text-blue-600 hover:text-blue-500"
            >
              Start a 14 day free trial
            </a>
          </p> */}
        </div>

        <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-sm flex justify-between">
          <a
            href="https://app.resure.realestate/tools"
            className="text-sm text-slate-500 hover:text-slate-700"
          >
            Login with RE-Sure
          </a>
          <a
            href="/forgot-password"
            className="text-sm text-slate-500 hover:text-slate-700"
          >
            Forgot password
          </a>
        </div>
      </div>

      <TermsPrivacyFooter />
    </>
  );
}
