const Header = () => {
  return (
    <header className="bg-rsblue-600 sticky top-0 left-0 z-40">
      <nav
        className="mx-auto flex items-center justify-between h-16 px-6"
        aria-label="Global"
      >
        <div className="flex">
          <a href="/" className="">
            <span className="sr-only">RE-Sure Sign</span>
            <img
              className="h-16 w-auto"
              src="/images/resure-sign-logo.png"
              alt="RE-Sure Sign"
            />
          </a>
        </div>
        {localStorage.getItem("id_token") ? (
          <div className="justify-end">
            <a
              href="/logout"
              className="text-sm font-semibold leading-6 text-white"
            >
              Log out
            </a>
          </div>
        ) : (
          <div className="justify-end">
            <a
              href="/login"
              className="text-sm font-semibold leading-6 text-white"
            >
              Log in
            </a>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;
