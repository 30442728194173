import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  CheckCircleIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";

import Sidebar from "./Sidebar";
import { FormContext } from "../Contexts/FormContext";

import AddDocumentModal from "../PdfEditor/components/AddDocumentModal";
import ConfirmDelete from "../Shared/UI/ConfirmDelete";

import { downloadForm } from "../Shared/requests";

export default function Templates() {
  const ctx = useContext(FormContext);
  const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);

  const navigate = useNavigate();

  let selectedTemplate = null;

  useEffect(() => {
    ctx.getTemplatesForUser();
  }, []);

  const { templateId } = useParams();

  if (templateId !== selectedTemplate?._id) {
    selectedTemplate = ctx.templates.find((t) => t._id === templateId);
  }

  //   console.log({ selectedTemplate });

  function toggleShowAddTemplateModal() {
    setShowAddTemplateModal((prevValue) => !prevValue);
  }

  function createFormFromTemplate(templateId) {
    ctx.createFormFromTemplate({ templateId });
  }

  function handleSelectTemplate(templateId) {
    navigate(`/templates/${templateId}`);
  }

  function handleUnselectTemplate() {
    navigate("/templates");
  }

  function download(formId) {
    const url = `${ctx.baseUrl}/forms/${formId}/download`;

    downloadForm({
      url,
      token: localStorage.getItem("id_token"),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${formId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  }

  function hanldeEditTemplate(templateId) {
    console.log("navigating to", templateId);
    window.location.href = `/edit?formId=${templateId}&mode=template`;
  }

  function handleDeleteClick({ id, mode }) {
    setDeleteModal(
      <ConfirmDelete
        title="Confirm Deletion"
        message={`Are you sure you want to delete this ${mode ? mode : "document"}? This action cannot be undone.`}
        onSuccess={() => {
          ctx.deleteDocument({
            documentId: id,
            mode,
          });
          setShowConfirmDeleteModal(false);
          setDeleteModal(null);
        }}
        onCancel={() => {
          setShowConfirmDeleteModal(false);
          setDeleteModal(null);
        }}
      />
    );

    setShowConfirmDeleteModal(true);
  }

  const templates = ctx.templates;

  const templateList = (
    <>
      <h1 className="font-bold text-xl">
        Templates{" "}
        <button
          type="button"
          onClick={toggleShowAddTemplateModal}
          className="text-lg font-normal"
        >
          (Add <PlusCircleIcon className="h-5 w-5 inline" />)
        </button>
      </h1>
      <div className="mt-5 overflow-hidden rounded-md bg-white shadow">
        <ul className="divide-y divide-gray-200">
          {templates.map((t) => (
            <div
              key={t._id}
              to={`/templates/${t._id}`}
              onClick={() => handleSelectTemplate(t._id)}
            >
              <li
                className={`px-6 py-4 hover:bg-slate-200 hover:cursor-pointer ${
                  t._id === templateId ? "bg-blue-200" : ""
                }`}
              >
                <div className="flex">
                  <span className="font-bold">
                    {t.templateName}{" "}
                    <span className="ml-5 rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                      Count: {t.forms.length}
                    </span>
                  </span>
                </div>
                <div>
                  Last Updated: {new Date(t.updatedAt).toLocaleString()}{" "}
                  <button
                    type="button"
                    onClick={() => hanldeEditTemplate(t._id)}
                    className="ml-5 rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 hover:bg-blue-100"
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      handleDeleteClick({ id: t._id, mode: "template" })
                    }
                  >
                    <TrashIcon className="fill-black h-4 w-4 inline hover:fill-red-600 ml-2" />
                  </button>
                </div>
              </li>
            </div>
          ))}
        </ul>
      </div>
    </>
  );

  let documentList = null;

  if (selectedTemplate) {
    documentList = (
      <>
        {" "}
        <h2 className="font-bold text-xl">
          Documents{" "}
          <button
            type="button"
            onClick={() => createFormFromTemplate(selectedTemplate._id)}
            className="text-lg font-normal"
          >
            (Add <PlusCircleIcon className="h-5 w-5 inline" />)
          </button>
        </h2>
        {selectedTemplate.forms.map((f) => {
          return (
            <div
              key={f._id}
              className="mt-2 bg-slate-100 p-2 rounded-md hover:bg-blue-100"
            >
              <div>
                Signers:{" "}
                <ul>
                  {f.signers.map((s) => {
                    return (
                      <li
                        className="pl-5 list-disc list-inside"
                        key={`${f._id}_${s._id}`}
                      >
                        {s.name}{" "}
                        {f.tracking.some(
                          (t) => t.signerId === s._id && t.signed?.length > 0
                        ) ? (
                          <CheckCircleIcon className="fill-green-500 h-4 w-4 inline" />
                        ) : f.tracking.some(
                            (t) => t.signerId === s._id && t.opened?.length > 0
                          ) ? (
                          <MinusCircleIcon className="fill-yellow-500 h-4 w-4 inline" />
                        ) : f.tracking.some(
                            (t) => t.signerId === s._id && t.sent?.length > 0
                          ) ? (
                          <MinusCircleIcon className="fill-red-500 h-4 w-4 inline" />
                        ) : null}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div>Created: {new Date(f.createdAt).toLocaleString()}</div>
              <div>
                {f.tracking.some((t) => t.completed)
                  ? `Completed: ${new Date(
                      f.tracking[0].completed
                    ).toLocaleString()}`
                  : `Updated: ${new Date(f.updatedAt).toLocaleString()}`}{" "}
              </div>
              <div className="mt-1 flex justify-between">
                <div>
                  <a
                    href={`/edit?formId=${f._id}`}
                    className="rounded-md bg-blue-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                  >
                    Open
                  </a>
                  <button
                    type="button"
                    className="ml-2 rounded-md bg-blue-500 px-2.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                    onClick={() => download(f._id)}
                  >
                    Download
                  </button>
                </div>
                <button
                  type="button"
                  onClick={() => handleDeleteClick({ id: f._id })}
                >
                  <TrashIcon className="fill-black h-4 w-4 inline hover:fill-red-600" />
                </button>
              </div>
            </div>
          );
        })}
      </>
    );
  }

  return (
    <>
      {showAddTemplateModal && (
        <AddDocumentModal
          onClose={toggleShowAddTemplateModal}
          mode="template"
        />
      )}
      {showConfirmDeleteModal && deleteModal}
      <Sidebar activeNav="Templates" />
      {/* <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">{/* Your content /}</div>
      </main> */}
      <main className="py-6 lg:py-10 lg:pl-72">
        <div className="xl:pr-96">
          <div className="px-4  sm:px-6 lg:px-8 ">
            {/* Main area */}
            {selectedTemplate ? (
              <>
                <div className="xl:hidden">
                  <div className="mb-2">
                    <button
                      type="button"
                      className="rounded-md bg-blue-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                      onClick={handleUnselectTemplate}
                    >
                      ... Back to Templates
                    </button>
                  </div>
                  {documentList}
                </div>
                <div className="hidden xl:block">{templateList}</div>
              </>
            ) : (
              templateList
            )}
          </div>
        </div>
      </main>
      {selectedTemplate && (
        <aside className="fixed inset-y-0 right-0 hidden w-96 overflow-y-auto border-l border-gray-200 px-4 py-6 sm:px-6 lg:px-8 xl:block">
          {/* Secondary column (hidden on smaller screens) */}
          {documentList}
        </aside>
      )}
      ;
    </>
  );
}
