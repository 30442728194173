import { useContext } from "react";

import {
  CursorArrowRaysIcon,
  FolderArrowDownIcon,
} from "@heroicons/react/20/solid";

import { FormContext } from "../Contexts/FormContext";

const ActionPanel = (props) => {
  const ctx = useContext(FormContext);

  const handleToggleHighlights = () => {
    ctx.toggleHighlightFields();
  };

  return (
    // <div id={props.styles["action-panel"]}>
    <div className="action-panel h-[calc(100vh_-_64px)] sticky top-16 lg:w-72 w-60 bg-blue-100 px-5 overflow-y-scroll">
      <div>
        <h3 className="text-lg font-bold mt-2">Other Actions</h3>
      </div>

      <div>
        <button
          type="button"
          className="inline-flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full mt-2"
          onClick={handleToggleHighlights}
        >
          <CursorArrowRaysIcon className="-ml-0.5 h-5 w-5 text-blue-800 mr-2" />
          {ctx.highlightFields ? "Remove Highlights" : "Highlight Fields"}
        </button>
      </div>

      <div>
        <button
          type="button"
          className="inline-flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full mt-2"
          onClick={ctx.download}
        >
          <FolderArrowDownIcon className="-ml-0.5 h-5 w-5 text-blue-800 mr-2" />
          Download
        </button>
      </div>
    </div>
  );
};

export default ActionPanel;
