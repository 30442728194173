import Sidebar from "./Sidebar";

export default function Reports() {
  return (
    <>
      <Sidebar activeNav="Reports" />
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          {/* Your content */}
          Reports
        </div>
      </main>
      ;
    </>
  );
}
