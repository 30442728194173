import { useContext } from "react";
import { FormContext } from "../../Contexts/FormContext";

const FONT_EDITABLE = [
  "signer-name",
  "signature-date",
  "input",
  "textarea",
  "signer-email",
];
const MAY_BE_COMPLETED_BY = ["input", "textarea"];
const REQUIRED_EDITABLE = ["input", "textarea"];
const CONVERT_TO_NUMBER = ["top", "left", "width", "height", "fontSize"];
const COLOR_EDITABLE = ["line"];

const DetailsPanel = ({ styles }) => {
  const ctx = useContext(FormContext);

  let panel = "";

  // console.log("---details panel---: ownerId", ctx.ownerId);

  const handleZoomIn = () => {
    if (ctx.scale < 2) {
      ctx.setScale(ctx.scale + 0.1);
    }
  };

  const handleZoomOut = () => {
    if (ctx.scale > 0.8) {
      ctx.setScale(ctx.scale - 0.1);
    }
  };

  const handleZoomReset = () => {
    if (ctx.scale !== 1) {
      ctx.setScale(1);
    }
  };

  const handleChange = (event) => {
    console.log(event);
    const update = {};
    if (event.target.type === "checkbox") {
      if (event.target.name === "completedBy") {
        const selectedOptions = document.querySelectorAll(
          `input[name='${event.target.name}']:checked`
        );
        const values = Array.from(selectedOptions).map((el) => el.value);
        update[event.target.name] = values;
      } else {
        update[event.target.name] = event.target.checked;
      }
    } else {
      if (CONVERT_TO_NUMBER.includes(event.target.name)) {
        if (
          ctx.selectedElement.type === "checkbox" &&
          (event.target.name === "width" || event.target.name === "height")
        ) {
          update["height"] = +event.target.value;
          update["width"] = +event.target.value;
        } else {
          update[event.target.name] = +event.target.value;
        }
      } else {
        update[event.target.name] = event.target.value;
      }
    }
    ctx.updateElement({ element: update, elementId: ctx.selectedElement._id });
    // onEditElementFromDetails(update);
  };

  const closeDocumentButton = (
    <div className="mt-5">
      <a href="/" className="bg-rsblue-600 py-2 px-3 rounded-md text-white">
        Close Document
      </a>
    </div>
  );

  if (ctx.selectedElement) {
    const showFontEditable =
      FONT_EDITABLE.includes(ctx.selectedElement.tag) &&
      ctx.selectedElement.type !== "checkbox";
    const showRequired = REQUIRED_EDITABLE.includes(ctx.selectedElement.tag);
    const showMayBeCompletedBy = MAY_BE_COMPLETED_BY.includes(
      ctx.selectedElement.tag
    );
    const showColor = COLOR_EDITABLE.includes(ctx.selectedElement.tag);

    panel = (
      <form id="details-panel">
        {closeDocumentButton}
        <div className="mt-5">
          <div>
            <span className="font-bold">Page:</span> {ctx.selectedElement.page}
          </div>
        </div>
        <div className="mt-2">
          <div className="font-bold">Position</div>
          <div className="relative mt-2 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-black-500 sm:text-sm">Top:</span>
            </div>
            <input
              key={`${ctx.selectedElement._id}_${ctx.selectedElement.top}_top`}
              type="number"
              name="top"
              className="block w-full rounded-md border-0 py-1.5 pl-16 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue={ctx.selectedElement.top}
              onChange={handleChange}
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="text-gray-500 sm:text-sm">px</span>
            </div>
          </div>
          <div className="relative mt-2 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-black-500 sm:text-sm">Left:</span>
            </div>
            <input
              key={`${ctx.selectedElement._id}_${ctx.selectedElement.left}_left`}
              type="number"
              name="left"
              className="block w-full rounded-md border-0 py-1.5 pl-16 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue={ctx.selectedElement.left}
              onChange={handleChange}
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="text-gray-500 sm:text-sm">px</span>
            </div>
          </div>
        </div>

        {ctx.selectedElement.isResizable && (
          <div className="mt-5">
            <div className="font-bold">Size</div>
            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-black-500 sm:text-sm">Width:</span>
              </div>
              <input
                key={`${ctx.selectedElement._id}_${ctx.selectedElement.width}_width`}
                type="number"
                name="width"
                className="block w-full rounded-md border-0 py-1.5 pl-16 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={ctx.selectedElement.width}
                onChange={handleChange}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm">px</span>
              </div>
            </div>

            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-black-500 sm:text-sm">Height:</span>
              </div>
              <input
                key={`${ctx.selectedElement._id}_${ctx.selectedElement.height}_height`}
                type="number"
                name="height"
                className="block w-full rounded-md border-0 py-1.5 pl-16 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={ctx.selectedElement.height}
                onChange={handleChange}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm">px</span>
              </div>
            </div>
          </div>
        )}

        {showColor && (
          <div className="mt-5">
            <div className="font-bold">Color</div>
            <select
              key={`${ctx.selectedElement._id}_${ctx.selectedElement.color}_color`}
              className="w-full p-1.5 rounded-md"
              onChange={handleChange}
              name="color"
              defaultValue={ctx.selectedElement.color}
            >
              <option value="black">Black</option>
              <option value="blue">Blue</option>
              <option value="red">Red</option>
              <option value="white">White</option>
            </select>
          </div>
        )}

        {showFontEditable && (
          <div className="mt-5">
            <div className="font-bold">Font</div>
            <select
              name="fontFamily"
              className="w-full p-1.5 rounded-md"
              key={`${ctx.selectedElement._id}_font-family`}
              onChange={handleChange}
              defaultValue={ctx.selectedElement.fontFamily || "inherit"}
            >
              <option value="inherit">Default</option>
              <option value="Times New Roman">Times New Roman</option>
              <option value="Helvetica">Helvetica</option>
            </select>

            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-black-500 sm:text-sm">Font Size:</span>
              </div>
              <input
                key={`${ctx.selectedElement._id}_font-size`}
                type="number"
                name="fontSize"
                className="block w-full rounded-md border-0 py-1.5 pl-20 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={ctx.selectedElement.fontSize || 12}
                onChange={handleChange}
                min="6"
                max="100"
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm">pt</span>
              </div>
            </div>
          </div>
        )}

        {/* {ctx.selectedElement.type === "checkbox" && (
          <p>
            Value if Checked:
            <input
              type="text"
              name="valueIfChecked"
              style={{width: '95%'}}
              defaultValue={ctx.selectedElement.valueIfChecked}
              onChange={handleChange}
            />
          </p>
        )} */}

        {showRequired && (
          <div className="mt-5">
            <span className="font-bold"> Required:</span>{" "}
            <input
              key={`${ctx.selectedElement._id}_required`}
              defaultChecked={ctx.selectedElement.required}
              type="checkbox"
              name="required"
              value="yes"
              onChange={handleChange}
            />
          </div>
        )}

        {showMayBeCompletedBy && (
          <div className="mt-5">
            <div className="font-bold">May Be Completed By: </div>

            <ul style={{ listStyleType: "none", margin: "0", padding: "0" }}>
              {ctx.signers
                .filter((s) => s._id !== ctx.ownerId)
                .map((s) => (
                  <li
                    key={`li_option_completed_by_${s._id}_for_${ctx.selectedElement._id}`}
                  >
                    <input
                      name="completedBy"
                      type="checkbox"
                      value={s._id}
                      onChange={handleChange}
                      defaultChecked={
                        ctx.selectedElement.completedBy &&
                        ctx.selectedElement.completedBy.includes(s._id)
                      }
                    />
                    {` ${s.name} (${s.email})`}
                  </li>
                ))}
            </ul>
          </div>
        )}
      </form>
    );
  } else {
    panel = closeDocumentButton;
  }

  return (
    // <div className="h-[calc(100vh_-_3.5rem)] sticky top-14 w-52 bg-[lightgreen]">
    <div className="details-panel h-[calc(100vh_-_64px)] sticky top-16 lg:w-72 w-60 bg-rsbluelight-600 px-5 overflow-y-scroll">
      <h2 className="text-xl font-bold mt-2">Details Panel</h2>
      {panel}

      <div className="mt-4 font-semibold">
        <div>Adjust Zoom</div>
        <div className="mt-2 flex space-x-2">
          <div className="">
            <img
              src="/images/zoom-in.png"
              alt="Zoom In"
              onClick={handleZoomIn}
              className="w-8"
            />
          </div>
          <div>
            <img
              src="/images/zoom-out.png"
              alt="Zoom Out"
              onClick={handleZoomOut}
              className="w-8"
            />
          </div>
          <div>
            <img
              src="/images/zoom-reset.png"
              alt="Zoom Reset"
              onClick={handleZoomReset}
              className="w-8"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DetailsPanel;
