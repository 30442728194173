import { useContext } from "react";
import { FormContext } from "../../Contexts/FormContext";

const SignButton = ({ styles }) => {
  const ctx = useContext(FormContext);

  const userSignatureElements = ctx.elements.filter(
    (e) =>
      (e.tag === "signature" || e.tag === "signer-initials") &&
      e.signerId === ctx.signedInUserId &&
      !e.value
  );

  const total = ctx.elements.filter(
    (e) =>
      (e.tag === "signature" || e.tag === "signer-initials") &&
      e.signerId === ctx.signedInUserId
  ).length;

  userSignatureElements.sort((a, b) => a.page - b.page || a.top - b.top);

  const searchListOfElements = userSignatureElements
    .map((e) => `#wrapper_${e._id}`)
    .join(",");

  let toSign;

  if (searchListOfElements) {
    toSign = document.querySelectorAll(searchListOfElements);
  }

  console.log({
    userSignatureElements,
    classListOfElements: searchListOfElements,
    toSign,
  });

  return userSignatureElements.length ? (
    <div
      key={`goto_${userSignatureElements[0]._id}`}
      style={{
        position: "sticky",
        zIndex: 100,
        backgroundColor: "cyan",
        // borderRadius: "5px",
        top: "50%",
        marginLeft: "10px",
        display: "inline-block",
        cursor: "pointer",
        color: "black",

        // boxShadow: "2px 2px 3px #999",
        // borderRadius: "5px",
        // padding: "2px 5px",

        // clipPath: "polygon(0 0, 65% 0, 100% 50%, 100% 50%, 65% 100%, 0 100%)",
        // padding: "2px 25px 2px 5px",

        // clipPath: "polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 25% 50%, 0% 0%)",
        // padding: "2px 15px 2px 25px",

        // clipPath: "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)",
        // padding: "2px 25px 2px 25px",

        clipPath:
          "polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%)",
        padding: "2px 20px 2px 20px",

        // clipPath: "polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%)",
        // padding: "2px 25px 2px 25px",
      }}
      onClick={() => {
        const element = document.getElementById(
          `wrapper_${userSignatureElements[0]._id}`
        );

        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }}
    >
      Sign{" "}
      <span className="text-2xs">
        ({total - userSignatureElements.length + 1} of {total})
      </span>
    </div>
  ) : null;
};

export default SignButton;
