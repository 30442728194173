export const addDocument = async ({ url, token, body }) => {
  let data;
  try {
    data = await sendRequest({ url, method: "POST", token, body });
  } catch (error) {
    throw new Error("Error creating template.");
  }

  console.log(data);
  return data;
};

export const deleteDocument = async ({ url, token }) => {
  let data;
  try {
    data = await sendRequest({ url, method: "DELETE", token });
  } catch (error) {
    throw new Error("Error deleting document.");
  }
  console.log(data);
  return data;
};

export const uploadPdf = async ({ url, body }) => {
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/pdf",
      },
      body,
    });

    if (!response.ok) {
      console.log(response);
      throw new Error("Error with request.");
    }

    return true;
  } catch (error) {
    console.log("ERROR:", error);
    throw new Error("Error uploading pdf.");
  }
};

export const getTemplates = async ({ url, token }) => {
  let data;
  try {
    data = await sendRequest({ url, method: "GET", token });
  } catch (error) {
    throw new Error("Error getting templates.");
  }

  return data.templates;
};

export const getDocuments = async ({ url, token }) => {
  let data;
  try {
    data = await sendRequest({ url, method: "GET", token });
  } catch (error) {
    throw new Error("Error getting documents.");
  }

  return data.documents;
};

export const createForm = async ({ url, token }) => {
  let data;
  try {
    data = await sendRequest({ url, method: "POST", token });
  } catch (error) {
    throw new Error("Error creating form.");
  }

  return data;
};

export const getForm = async ({ url, token }) => {
  let data;
  try {
    data = await sendRequest({ url, method: "GET", token });
  } catch (error) {
    throw new Error("Error fetching form.");
  }

  // console.log("getform():", data.form);
  return data.form;
};

export const getFormBySid = async ({ url, formId, sid }) => {
  let data;
  try {
    data = await sendRequest({ url, method: "POST", body: { formId, sid } });
  } catch (error) {
    console.log(error);
    throw new Error("An error occured while verifying sid.");
  }

  return data;
};

export const getElements = async ({ url, token }) => {
  let data;
  try {
    data = await sendRequest({ url, method: "GET", token });
  } catch (error) {
    throw new Error("Error fetching elements.");
  }

  console.log("getElements():", data.elements);
  return data.elements;
};

export const addElement = async ({ url, token, element }) => {
  // console.log(element);
  let data;
  try {
    data = await sendRequest({ url, method: "POST", token, body: element });
  } catch (error) {
    console.log(error);
    throw new Error("An error occured while adding element.");
  }

  return data;
};

export const editElement = async ({ url, token, element, updateInfo }) => {
  let data;
  try {
    data = await sendRequest({
      url,
      method: "PATCH",
      token,
      body: { element, updateInfo },
    });
  } catch (error) {
    console.log(error);
    throw new Error("An error occured while editing element.");
  }

  console.log("editElement()", data);
  return data;
};

export const removeElement = async ({ url, token }) => {
  let data;
  try {
    data = await sendRequest({ url, method: "DELETE", token });
  } catch (error) {
    throw new Error("An error occured while deleting element.");
  }

  return data;
};

export const addSigner = async ({ url, token, signer }) => {
  let data;
  try {
    data = await sendRequest({ url, method: "POST", token, body: signer });
  } catch (error) {
    throw new Error("An error occured while adding signer.");
  }

  return data;
};

export const editSigner = async ({ url, token, signer }) => {
  let data;
  try {
    data = await sendRequest({ url, method: "PATCH", token, body: signer });
  } catch (error) {
    throw new Error("An error occured while editing signer.");
  }

  return data;
};

export const removeSigner = async ({ url, token }) => {
  let data;
  try {
    data = await sendRequest({ url, method: "DELETE", token });
  } catch (error) {
    throw new Error("An error occured while deleting signer.");
  }

  return data;
};

export const sendFormForSignatures = async ({
  url,
  token,
  customMessage,
  recipients,
}) => {
  let data;
  try {
    data = await sendRequest({
      url,
      method: "POST",
      token,
      body: { customMessage, recipients },
    });
  } catch (error) {
    throw new Error("Error sending for signatures.");
  }

  return data;
};

export async function verifyToken({ url, token }) {
  let data;
  try {
    data = await sendRequest({ url, method: "GET", token });
  } catch (error) {
    throw new Error("Error validating token.");
  }

  return data;
}

export async function getTrackingForForm({ url, token }) {
  let data;
  try {
    data = await sendRequest({ url, method: "GET", token });
  } catch (error) {
    throw new Error("Error fetching tracking data.");
  }

  return data;
}

export async function addEmailTemplate({
  url,
  token,
  emailTemplateBody,
  emailTemplateTitle,
}) {
  let data;
  try {
    data = await sendRequest({
      url,
      method: "POST",
      token,
      body: { emailTemplateBody, emailTemplateTitle },
    });
  } catch (error) {
    throw new Error("Error creating email template.");
  }

  return data;
}

export async function getEmailTemplates({ url, token }) {
  let data;
  try {
    data = await sendRequest({ url, method: "GET", token });
  } catch (error) {
    throw new Error("Error fetching email templates.");
  }

  return data;
}

export async function updateEmailTemplate({
  url,
  token,
  emailTemplateBody,
  emailTemplateTitle,
}) {
  let data;
  try {
    data = await sendRequest({
      url,
      method: "PATCH",
      token,
      body: { emailTemplateBody, emailTemplateTitle },
    });
  } catch (error) {
    throw new Error("Error updating email template.");
  }

  return data;
}

export async function deleteEmailTemplate({ url, token }) {
  let data;
  try {
    data = await sendRequest({ url, method: "DELETE", token });
  } catch (error) {
    throw new Error("Error deleting email template.");
  }

  return data;
}

export async function downloadForm({ url, token }) {
  let urlWithMode = url;
  const search = new URLSearchParams(window.location.search);

  if (search.get("mode") === "template") {
    urlWithMode += "?mode=template";
  }

  const options = {};

  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  try {
    const response = await fetch(urlWithMode, options);
    checkFor401(response);

    if (!response.ok) {
      console.log(response);
      const data = await response.json();
      console.log(data);
      throw new Error("Error with request.");
    }

    const data = await response.blob();
    return data;
  } catch (error) {
    console.log(error);
    throw new Error("Error downloading document.");
  }
}

async function sendRequest({ url, method, token, body }) {
  let urlWithMode = url;
  const search = new URLSearchParams(window.location.search);

  if (search.get("mode") === "template") {
    urlWithMode += "?mode=template";
  }

  console.log(urlWithMode);

  const options = {
    method: method,
  };

  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  if (body) {
    options.body = JSON.stringify(body);
    if (!options.headers) options.headers = {};
    options.headers["Content-Type"] = "application/json";
  }

  const response = await fetch(urlWithMode, options);
  checkFor401(response);

  const data = await response.json();
  if (!response.ok) {
    console.log(response);
    console.log(data);
    throw new Error("Error with request.");
  }

  return data;
}

function checkFor401(response) {
  if (response.status === 401) {
    localStorage.removeItem("id_token");
    window.location.href = "/login";
  }
}
