import { useEffect } from "react";
import Sidebar from "./Sidebar";

export default function Dashboard() {
  useEffect(() => {
    window.location.href = "/documents";
  });
  return (
    <>
      <Sidebar activeNav="Dashboard" />
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          {/* Your content */}
          Dashboard
        </div>
      </main>
      ;
    </>
  );
}
