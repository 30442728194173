import { useContext, useState } from "react";
import SignaturePanel from "./SignaturePanel";
import { FormContext } from "../../../Contexts/FormContext";

const SignaturePlaceholder = (props) => {
  const ctx = useContext(FormContext);
  const [showSignaturePanel, setShowSignaturePanel] = useState(false);

  const sigType = props.type === "initials" ? "initials" : "signature";
  const sigTypeLabel = props.type === "initials" ? "Init." : "Signature";
  // console.log({ sigTypeLabel });

  const handleSignDocumentClicked = () => {
    // console.log('handleSignDocumentClicked()')
    if (ctx.hasRequiredElementsMissing) {
      alert("Required elements missing. Complete form before signing.");
    } else setShowSignaturePanel(true);
  };

  const handleCloseWithoutSaving = () => {
    setShowSignaturePanel(false);
  };

  const handleSignatureSave = ({
    signature,
    metadata,
    agreeToESign,
    agreeToESignDate,
  }) => {
    // console.log("handleSignatureSave()", signature);
    console.log({ agreeToESign, agreeToESignDate })
    ctx.updateElement({
      element: {
        value: { signature, date: new Date(), metadata },
        agreeToESign,
        agreeToESignDate,
      },
      elementId: props.item._id,
      type: sigType === "signature" ? "signature" : "initials",
    });
    setShowSignaturePanel(false);
  };

  const isLoggedInSigner = props.item.signerId === ctx.signedInUserId;

  let isSigned = false;
  if (props.item.value && props.item.value.signature) {
    isSigned = true;
  }
  let returnJsx;

  if (!isSigned) {
    returnJsx = (
      <>
        {showSignaturePanel && (
          <SignaturePanel
            item={props.item}
            styles={props.styles}
            onCloseWithoutSaving={handleCloseWithoutSaving}
            showSignaturePanel={showSignaturePanel}
            onSaveSignature={handleSignatureSave}
            sigType={sigType}
          />
        )}
        <div
          className={`${props.styles["signature-placeholder"]} ${
            isLoggedInSigner ? props.styles["active"] : ""
          }`}
          onClick={
            ctx.mode !== "template" && isLoggedInSigner
              ? handleSignDocumentClicked
              : null
          }
        >
          {sigTypeLabel}
        </div>
      </>
    );
  } else {
    returnJsx = (
      <div
        style={{
          backgroundImage: `url("${props.item.value.signature}")`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          width: `${props.item.width * ctx.scale}px`,
          height: `${props.item.height * ctx.scale}px`,
        }}
      ></div>
    );
  }

  return returnJsx;
};

export default SignaturePlaceholder;
