import { useState } from "react";
import toast from "react-hot-toast";

import TermsPrivacyFooter from "../Shared/UI/TermsPrivacyFooter";

export default function ForgotPassword() {
  const [message, setMessage] = useState(null);

  function handleFormSubmit(event) {
    event.preventDefault();
    setMessage(null);
    const loginInfo = Object.fromEntries(new FormData(event.target));

    fetch(`${process.env.REACT_APP_API_BASE_URL}/user/forgot-password`, {
      method: "post",
      body: JSON.stringify(loginInfo),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((data) => {
        console.log(response.ok);
        console.log(data);

        if (!response.ok) {
          setMessage(
            <div className="text-red-500 text-center">
              Unable to send password reset link. Please try again later.
            </div>
          );
        } else {
          toast.success("If the email exists, a reset link has been sent.");
          setMessage(
            <div className="text-green-600 text-center">
              If the email exists, a reset link has been sent.
            </div>
          );
        }
      });
    });
  }

  return (
    <>
      {/*
          This example requires updating your template:
  
          ```
          <html class="h-full bg-white">
          <body class="h-full">
          ```
        */}
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto w-2/3"
            src="/images/resure-sign-logo-black.png"
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Forgot Password
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleFormSubmit}>
            {message ? message : null}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Request Password Reset Link
              </button>
            </div>
          </form>

          {/* <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{" "}
            <a
              href="#"
              className="font-semibold leading-6 text-blue-600 hover:text-blue-500"
            >
              Start a 14 day free trial
            </a>
          </p> */}
        </div>

        <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-sm flex justify-between">
          <a
            href="https://app.resure.realestate/tools"
            className="text-sm text-slate-500 hover:text-slate-700"
          >
            Login with RE-Sure
          </a>
          <a
            href="/login"
            className="text-sm text-slate-500 hover:text-slate-700"
          >
            Back to login
          </a>
        </div>

        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm flex justify-between">
          Password reset is not available for RE-Sure Real Estate users. Please
          use the Login with RE-Sure link to log in.
        </div>
      </div>

      <TermsPrivacyFooter />
    </>
  );
}
