import { useEffect } from "react";

export default function Logout() {
  useEffect(() => {
    localStorage.removeItem("id_token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    // Redirect to login or another page after logout
    window.location.href = "/login";
  }, []);

  return null;
}
