import { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";

import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  DocumentDuplicateIcon,
  FolderIcon,
  XMarkIcon,
  PlusCircleIcon,
  FunnelIcon as FunnelIconOutline,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";

import { FunnelIcon as FunnelIconSold } from "@heroicons/react/24/solid";

import AddDocumentModal from "../PdfEditor/components/AddDocumentModal";

import { FormContext } from "../Contexts/FormContext";

const navigation = [
  // { name: "Dashboard", href: "/", icon: HomeIcon, current: true },
  {
    name: "Documents",
    href: "/documents",
    icon: DocumentDuplicateIcon,
    current: false,
    actionButton: PlusCircleIcon,
    actionFunction: "addDocument",
  },
  {
    name: "Templates",
    href: "/templates",
    icon: FolderIcon,
    current: false,
    actionButton: PlusCircleIcon,
    actionFunction: "addTemplate",
  },
  // { name: "Reports", href: "/reports", icon: ChartPieIcon, current: false },
];

// eslint-disable-next-line no-unused-vars
const documentFilters = [
  {
    id: 0,
    name: "Signable",
    href: "/documents/signable",
    current: false,
  },
  {
    id: 1,
    name: "Signed",
    href: "/documents/signed",
    current: false,
  },
  {
    id: 2,
    name: "Completed",
    href: "/documents/completed",
    current: false,
  },
  // {
  //   id: 2,
  //   name: "In Progress",
  //   href: "/documents/in-progress",
  //   initial: "IP",
  //   current: false,
  // },
  // {
  //   id: 3,
  //   name: "Opened",
  //   href: "/documents/opened",
  //   initial: "O",
  //   current: false,
  // },
  // {
  //   id: 4,
  //   name: "Sent",
  //   href: "/documents/sent",
  //   initial: "S",
  //   current: false,
  // },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Sidebar(props) {
  const ctx = useContext(FormContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showAddDocumentModal, setShowAddDocumentModal] = useState(false);
  const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);

  const user = ctx.getUser();
  if (user && user.userType && user.userType.toUpperCase() === "SIGNER") {
    localStorage.removeItem("id_token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    window.location.href = "/";
  }

  function toggleShowAddDocumentModal() {
    setShowAddDocumentModal((prevValue) => !prevValue);
  }

  function toggleShowAddTemplateModal() {
    setShowAddTemplateModal((prevValue) => !prevValue);
  }

  function handleAddDocumentOrTemplate(action) {
    sidebarOpen && setSidebarOpen(false);
    if (action === "addDocument") {
      toggleShowAddDocumentModal();
    } else if (action === "addTemplate") {
      toggleShowAddTemplateModal();
    }
  }

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}

      {showAddDocumentModal && (
        <AddDocumentModal
          onClose={toggleShowAddDocumentModal}
          mode="document"
        />
      )}

      {showAddTemplateModal && (
        <AddDocumentModal
          onClose={toggleShowAddTemplateModal}
          mode="template"
        />
      )}

      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-rsblue-600 px-6 pb-2">
                  <div className="flex shrink-0 justify-center items-center pt-4">
                    <a href="/">
                      <img
                        className="w-auto h-32"
                        src="/images/resure-sign-logo.png"
                        alt="RE-Sure Sign"
                      />
                    </a>
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li
                              key={item.name}
                              className={classNames(
                                item.name === props.activeNav
                                  ? "bg-rsblue-900 text-white"
                                  : "text-blue-200 hover:text-white hover:bg-rsblue-900",
                                "flex justify-between rounded-md items-center pr-2"
                              )}
                            >
                              <Link
                                to={item.href}
                                className={classNames(
                                  item.name === props.activeNav
                                    ? "bg-rsblue-900 text-white"
                                    : "text-blue-200 hover:text-white hover:bg-rsblue-900",
                                  "group flex justify-between rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <span className="flex gap-x-3">
                                  <item.icon
                                    className={classNames(
                                      item.name === props.activeNav
                                        ? "text-white"
                                        : "text-blue-200 group-hover:text-white",
                                      "h-6 w-6 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </span>
                              </Link>
                              <item.actionButton
                                className={classNames(
                                  "text-white h-6 w-6 shrink-0 cursor-pointer"
                                )}
                                onClick={() =>
                                  handleAddDocumentOrTemplate(
                                    item.actionFunction
                                  )
                                }
                              />
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <div className="text-xs font-semibold leading-6 text-blue-200">
                          Document Filters
                        </div>
                        <ul className="-mx-2 mt-2 space-y-1">
                          {documentFilters.map((item) => (
                            <li key={item.name}>
                              <Link
                                to={item.href}
                                className={classNames(
                                  item.name === props.activeNav
                                    ? "bg-rsblue-900 text-white"
                                    : "text-blue-200 hover:text-white hover:bg-rsblue-900",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                {item.name === props.activeNav ? (
                                  <FunnelIconSold className="w-4" />
                                ) : (
                                  <FunnelIconOutline className="w-4" />
                                )}
                                <span className="truncate">{item.name}</span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-rsblue-600 px-6">
          <div className="flex shrink-0 pt-4 items-center justify-center">
            <a href="/" className="w-3/4">
              <img src="/images/resure-sign-logo.png" alt="RE-Sure Sign" />
            </a>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li
                      key={item.name}
                      className={classNames(
                        item.name === props.activeNav
                          ? "bg-rsblue-900 text-white"
                          : "text-blue-200 hover:text-white hover:bg-rsblue-900",
                        "flex justify-between rounded-md items-center pr-2"
                      )}
                    >
                      <Link
                        to={item.href}
                        className={classNames(
                          item.name === props.activeNav
                            ? "bg-rsblue-900 text-white"
                            : "text-blue-200 hover:text-white hover:bg-rsblue-900",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold grow"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.name === props.activeNav
                              ? "text-white"
                              : "text-blue-200 group-hover:text-white",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                      <item.actionButton
                        className={classNames(
                          "text-white h-6 w-6 shrink-0 cursor-pointer"
                        )}
                        onClick={() =>
                          handleAddDocumentOrTemplate(item.actionFunction)
                        }
                      />
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <div className="text-xs font-semibold leading-6 text-blue-200">
                  Document Filters
                </div>
                <ul className="-mx-2 mt-2 space-y-1">
                  {documentFilters.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className={classNames(
                          item.name === props.activeNav
                            ? "bg-rsblue-900 text-white"
                            : "text-blue-200 hover:text-white hover:bg-rsblue-900",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        {item.name === props.activeNav ? (
                          <FunnelIconSold className="w-4" />
                        ) : (
                          <FunnelIconOutline className="w-4" />
                        )}

                        <span className="truncate">{item.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="-mx-6 mt-auto">
                <ul>
                  <li className="px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-rsblue-900">
                    <Link
                      to="/email-templates"
                      className={classNames(
                        props.activeNav === "Email Templates"
                          ? "bg-rsblue-900 text-white"
                          : "text-blue-200 hover:text-white hover:bg-rsblue-900",
                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      )}
                    >
                      <span className="flex">
                        <EnvelopeIcon className="h-6 pr-4" /> Email Templates
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-rsblue-900"
                    >
                      {user?.profileImage ? (
                        <img
                          className="h-8 w-8 rounded-full bg-rsblue-900"
                          src={user.profileImage}
                          alt="Avatar"
                        />
                      ) : (
                        <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
                          <svg
                            className="h-full w-full text-gray-300"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                          </svg>
                        </span>
                      )}

                      <span className="sr-only">Your profile</span>
                      <span aria-hidden="true">
                        {user?.name || "RE-Sure Sign User"}
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-rsblue-600 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-rsbluelight-600 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 shrink-0 justify-center items-center">
          <a href="/">
            <img
              className="h-16"
              src="/images/resure-sign-logo.png"
              alt="RE-Sure Sign"
            />
          </a>
        </div>

        {/* <div className="flex-1 text-sm font-semibold leading-6 text-white">
          RE-Sure Sign
        </div> */}
        <Link to="#">
          <span className="sr-only">Your profile</span>

          {user?.profileImage ? (
            <img
              className="h-8 w-8 rounded-full bg-rsblue-900"
              src={user.profileImage}
              alt="Avatar"
            />
          ) : (
            <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
              <svg
                className="h-full w-full text-gray-300"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
          )}
        </Link>
      </div>
    </>
  );
}
