import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import SignatureCanvas from "react-signature-canvas";
import trimCanvas from "trim-canvas";

import styles from "./SignaturePanel.module.css";
import { FormContext } from "../../../Contexts/FormContext";

const SignaturePanel = (props) => {
  const ctx = useContext(FormContext);

  const sigType = props.sigType;

  let userName =
    ctx.signers.find((s) => s._id === ctx.signedInUserId)?.name || "";

  if (sigType === "initials") {
    const parts = userName.trim().split(" ");
    userName = parts.map((n) => n[0]).join("");
  }

  const [signatureMethod, setSignatureMethod] = useState("type");
  const [signatureTextValue, setSignatureTextValue] = useState(userName);
  const [ip, setIp] = useState(null);
  const [geo, setGeo] = useState(null);

  useEffect(() => {
    fetch("https://api64.ipify.org?format=json").then((response) => {
      response.json().then((data) => {
        setIp(data.ip);
      });
    });

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setGeo({
          lat: position.coords.latitude,
          long: position.coords.longitude,
        });
      },
      (error) => {
        console.log(error);
        alert("Please enable location access for signature verification.");
      },
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
    );
  }, []);

  const signaturePad = useRef();

  const handleToggleMethod = () => {
    if (signatureMethod === "draw") {
      setSignatureMethod("type");
    } else {
      setSignatureMethod("draw");
    }
  };

  const handleSignatureClear = () => {
    if (signatureMethod === "draw") {
      signaturePad.current.clear();
    } else {
    }
  };

  const handleSignatureSave = (event) => {
    event.preventDefault();
    if (signatureMethod === "draw") {
      props.onSaveSignature({
        signature: signaturePad.current
          .getTrimmedCanvas()
          .toDataURL("image/png"),
        metadata: { ip, geo },
        agreeToESign: document.getElementById("agreeToEsign").checked,
        agreeToESignDate: new Date(),
      });
    } else {
      const canvas = document.createElement("canvas");
      canvas.width = 1000;
      canvas.height = 500;
      const context = canvas.getContext("2d");

      const selectedFont =
        document.getElementById("signatureFontFamily").value || "Allura";

      context.font = `48pt ${selectedFont}`;
      context.fillText(signatureTextValue, 20, 72);

      props.onSaveSignature({
        signature: trimCanvas(canvas).toDataURL("image/png"),
        metadata: { ip, geo },
        agreeToESign: document.getElementById("agreeToEsign").checked,
        agreeToESignDate: new Date(),
      });
    }
  };

  const handleCloseWithoutSaving = () => {
    handleSignatureClear();
    props.onCloseWithoutSaving();
  };

  const handleFontChanged = (event) => {
    console.log(event);
    document.getElementById(
      "signature-name-preview"
    ).style.fontFamily = `'${event.target.value}', cursive`;
  };

  const handleSignatureTextChanged = (event) => {
    setSignatureTextValue(event.target.value);
  };

  let signatureMethodObject =
    signatureMethod === "draw" ? (
      <SignatureCanvas
        ref={signaturePad}
        penColor="black"
        canvasProps={{
          className: `${props.styles["signature-panel-canvas"]}`,
        }}
      />
    ) : (
      <div className={styles["text-signing-area"]}>
        <div className="mt-1">
          <span className="w-1/4 inline-block"> Select Font: </span>
          <select
            className="w-2/3 rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
            id="signatureFontFamily"
            name="signatureFontFamily"
            onChange={handleFontChanged}
          >
            <option value="Allura">Allura</option>
            <option value="Dancing Script">Dancing Script</option>
            <option value="Pacifico">Pacifico</option>
            <option value="Shadows Into Light">Shadows Into Light</option>
            <option value="Whisper">Whisper</option>
          </select>
        </div>
        <div className="mt-1">
          <span className="w-1/4 inline-block">{`Type ${sigType}: `}</span>
          <input
            type="text"
            className="w-2/3 rounded-md border-0 px-1 py-1 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            name="signatureValue"
            value={signatureTextValue}
            onInput={handleSignatureTextChanged}
            maxLength={sigType === "initials" ? 3 : undefined}
          />
        </div>
        <div
          id="signature-name-preview"
          style={{ fontFamily: "Allura, cursive", fontSize: "24pt" }}
          className="text-center mt-2"
        >
          {signatureTextValue}
        </div>
      </div>
    );

  return (
    <Transition appear show={props.showSignaturePanel} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[200]"
        onClose={handleCloseWithoutSaving}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Sign Document
                </Dialog.Title>
                <form onSubmit={handleSignatureSave}>
                  <div className="mt-2">
                    <div className="">
                      {signatureMethodObject}
                      <div className="text-sm">
                        The parties agree that this agreement may be
                        electronically signed. The parties agree that the
                        electronic signatures appearing on this agreement are
                        the same as handwritten signatures for the purposes of
                        validity, enforceability, and admissibility.
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="agreeToEsign"
                          name="agreeToEsign"
                          className="rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                          required={true}
                        />
                        <label htmlFor="agreeToEsign" className="ml-2 text-sm">
                          <span className="text-red-500">*</span> I agree to the
                          terms of electronic signature and RE-Sure Sign{" "}
                          <a
                            href="/terms"
                            target="_blank"
                            className="text-blue-900"
                          >
                            Terms of Service
                          </a>
                          .
                        </label>
                      </div>
                      <div className="mt-2 flex justify-center">
                        <button
                          type="button"
                          className="mr-2 rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                          onClick={handleToggleMethod}
                        >
                          Switch to{" "}
                          {signatureMethod === "draw" ? "type" : "draw"}
                        </button>
                        <button
                          type="button"
                          className="mr-2 rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                          onClick={handleSignatureClear}
                        >
                          Clear
                        </button>
                        <button
                          type="submit"
                          className="rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-50"
                        >
                          Apply Signature
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SignaturePanel;
