import { useContext } from "react";
import FormElement from "./FormElement";
import { FormContext } from "../../Contexts/FormContext";

const FormElements = ({ page, styles }) => {
  const ctx = useContext(FormContext);
  return (
    <div
      key={`annotations_page_${page}`}
      id={`annotations_page_${page}`}
      // className={styles["page-annotations"]}
      className="absolute z-30"
    >
      {ctx.elements
        .filter((e) => e.page === page)
        .map((item) => (
          <FormElement key={`fe_${item._id}`} item={item} styles={styles} />
        ))}
    </div>
  );
};

export default FormElements;
