import { EnvelopeIcon } from "@heroicons/react/24/solid";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";

function handleInsertName() {
  const emailTemplateBody = document.getElementById("emailTemplateBody");
  const cursorPosition = emailTemplateBody.selectionStart;
  const emailTemplateBodyValue = emailTemplateBody.value;
  const emailTemplateBodyValueBeforeCursor = emailTemplateBodyValue.substring(
    0,
    cursorPosition
  );
  const emailTemplateBodyValueAfterCursor =
    emailTemplateBodyValue.substring(cursorPosition);
  emailTemplateBody.value = `${emailTemplateBodyValueBeforeCursor}{{name}}${emailTemplateBodyValueAfterCursor}`;
  emailTemplateBody.focus();
  emailTemplateBody.selectionStart = cursorPosition;
  emailTemplateBody.selectionEnd = cursorPosition + 8;
}

export default function EmailTemplateModal(props) {
  return (
    <Dialog open={true} onClose={props.onClose} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-rsblue-600">
                <EnvelopeIcon
                  aria-hidden="true"
                  className="h-6 w-6 text-white"
                />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <DialogTitle
                  as="h3"
                  className="text-base font-semibold leading-6 text-gray-900"
                >
                  {props.modalTitle}
                </DialogTitle>
                <div className="mt-2">
                  <div>
                    <label
                      htmlFor="emailTemplateTitle"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    ></label>
                    <div>
                      <input
                        type="text"
                        name="emailTemplateTitle"
                        id="emailTemplateTitle"
                        placeholder="Enter email template title"
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-rsblue-600 sm:text-sm sm:leading-6"
                        defaultValue={props.emailTemplateTitle}
                      />
                    </div>
                  </div>
                  <div className="mt-2 flex">
                    <h3 className="font-semibold">Insert Merge Field:</h3>
                    <button
                      type="button"
                      className="ml-2 px-2 rounded-md bg-rsblue-600 text-white"
                      onClick={handleInsertName}
                    >
                      Name
                    </button>
                  </div>
                  <div className="mt-2">
                    <textarea
                      id="emailTemplateBody"
                      name="emailTemplateBody"
                      rows={3}
                      className="block w-full rounded-md border-0 h-48 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-rsblue-600 sm:text-sm sm:leading-6"
                      placeholder="Enter email template body"
                      defaultValue={props.emailTemplateBody}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                onClick={props.onSave}
                className="inline-flex w-full justify-center rounded-md bg-rsblue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rsblue-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
              >
                {props.saveButtonLabel}
              </button>
              <button
                type="button"
                data-autofocus
                onClick={props.onClose}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              >
                Cancel
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
