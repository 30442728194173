import { useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";

import { QuestionMarkCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";

const FloatingHelpButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  function handleToggleOpen() {
    setIsOpen((prev) => !prev);
  }

  if (isOpen) {
    return (
      <Dialog
        open={isOpen}
        onClose={handleToggleOpen}
        className="relative z-50"
      >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-2xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
              >
                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <DialogTitle className="text-xl font-semibold leading-6 text-gray-900 ">
                        RE-Sure Sign Help
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={() => setIsOpen(false)}
                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-6 flex-1 px-4 sm:px-6">
                    <h3 className="">RE-Sure Sign Overview</h3>
                    <div
                      style={{
                        position: "relative",
                        paddingBottom: "56.25%",
                        height: 0,
                      }}
                    >
                      <iframe
                        title="resure-sign-help-overview"
                        src="https://www.loom.com/embed/13cc17ae69a6476cbf696130f3ea352f?sid=d38d9246-394a-47f3-9f3b-74e0d73a37c8"
                        frameborder="0"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allowfullscreen
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      ></iframe>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    );
  } else {
    return (
      <div className="fixed bottom-4 right-4 z-50" onClick={handleToggleOpen}>
        <button className="bg-rsblue-600  text-white hover:bg-white hover:text-rsblue-600 font-bold p-2 rounded-full shadow-lg">
          <QuestionMarkCircleIcon className="w-8" />
        </button>
      </div>
    );
  }
};

export default FloatingHelpButton;
