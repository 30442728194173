import { Fragment, useContext, useState } from "react";

import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Transition,
} from "@headlessui/react";

import { CheckCircleIcon } from "@heroicons/react/24/solid";

import {
  PlusIcon,
  PaperAirplaneIcon,
  CursorArrowRaysIcon,
  FolderArrowDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

import SignerElements from "./SignerElements";
import { FormContext } from "../../Contexts/FormContext";

const ActionPanel = (props) => {
  const ctx = useContext(FormContext);
  const [selectedSigner, setSelectedSigner] = useState([]);
  const [addSignerIsOpen, setAddSignerIsOpen] = useState(false);
  const [showSendFormMessageModal, setShowSendFormMessageModal] =
    useState(false);
  const [showSelectEmailTemplateModal, setShowSelectEmailTemplateModal] =
    useState(false);

  const handleSelectedSigner = (event) => {
    setSelectedSigner(ctx.signers.filter((s) => s._id === event.target.value));
  };

  function handleAddSelfSigner() {
    const self = JSON.parse(
      atob(localStorage.getItem("id_token").split(".")[1])
    );
    ctx.addSigner({
      signer: {
        _id: ctx.signedInUserId,
        name: self?.name || "",
        email: self?.email || "",
      },
    });
  }

  const handleAddSignerOpen = () => {
    setAddSignerIsOpen(true);

    // ctx.addSigner({ signer: { name: "Add name", email: "Add email" } });
  };

  const handleAddSigner = (event) => {
    event.preventDefault();
    const signer = Object.fromEntries(new FormData(event.target));
    ctx.addSigner({ signer });
    setAddSignerIsOpen(false);
  };

  const handleRemoveSigner = () => {
    setSelectedSigner([]);
  };

  const handleSendForm = (event) => {
    event.preventDefault();
    const recipients = [];
    const recipientsSelected = document.getElementsByName("recipients");

    recipientsSelected.forEach(
      (r) => r.checked && recipients.push({ _id: r.value })
    );

    console.log({ recipients });
    // show modal for custom message
    let customMessage = event.target.customMessage.value.trim();
    setShowSendFormMessageModal(false);

    ctx.sendFormForSignatures({
      customMessage,
      recipients,
    });
  };

  const handleToggleHighlights = () => {
    ctx.toggleHighlightFields();
  };

  const handleAddElement = (event) => {
    let target = event.target;

    if (!target.dataset?.elementAdded) {
      target = event.target.parentNode;
    }

    const newElement = {
      page: 1,
      top: (window.scrollY + window.innerHeight / 2) / ctx.scale,
      left: 306,
    };

    console.log({
      scale: ctx.scale,
      newElement,
      scrollY: window.scrollY,
      innerHeight: window.innerHeight,
    });

    switch (target.dataset?.elementAdded) {
      case "checkbox":
        newElement.tag = "input";
        newElement.type = "checkbox";
        newElement.isDraggable = true;
        newElement.isResizable = true;
        newElement.width = 13;
        newElement.height = 13;
        break;
      case "textarea":
        newElement.tag = "textarea";
        newElement.width = 250;
        newElement.height = 60;
        newElement.isDraggable = true;
        newElement.isResizable = true;
        newElement.fontSize = 10;
        break;
      case "line":
        newElement.tag = "line";
        newElement.width = 400;
        newElement.height = 1;
        newElement.isDraggable = true;
        newElement.isResizable = true;
        newElement.color = "black";
        break;
      default:
        newElement.tag = "input";
        newElement.type = target.dataset.elementAdded || "text";
        newElement.width = 100;
        newElement.isDraggable = true;
        newElement.isResizable = true;
        newElement.fontSize = 10;
        break;
    }

    ctx.addElement({ element: newElement });
  };

  function handleShowEmailTemplates() {
    ctx.getEmailTemplates();
    setShowSelectEmailTemplateModal(true);
  }

  function handleEmailTemplateSelected(templateText) {
    console.log(templateText);

    document.querySelector("#customMessage").value = templateText;
    setShowSelectEmailTemplateModal(false);
  }

  console.log({ emailTemplates: ctx.emailTemplates });

  return (
    // <div id={props.styles["action-panel"]}>
    <div className="action-panel h-[calc(100vh_-_64px)] sticky top-16 lg:w-72 w-60 bg-rsbluelight-600 px-5 overflow-y-scroll">
      {!ctx.isSigned && (
        <>
          <h2 className="text-xl font-bold mt-2">Action Panel</h2>
          <div>
            <button
              type="button"
              className="inline-flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full mt-2"
              data-element-added="text"
              onClick={handleAddElement}
              disabled={ctx.isSigned}
            >
              <PlusIcon className="-ml-0.5 h-5 w-5 bg-rsblue-800 rounded-full text-white mr-2" />
              Single Line Text
            </button>
            <button
              type="button"
              className="inline-flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full mt-2"
              data-element-added="textarea"
              onClick={handleAddElement}
              disabled={ctx.isSigned}
            >
              <PlusIcon className="-ml-0.5 h-5 w-5 bg-rsblue-800 rounded-full text-white mr-2" />
              Multi Line Text
            </button>

            <button
              type="button"
              className="inline-flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full mt-2"
              data-element-added="date"
              onClick={handleAddElement}
              disabled={ctx.isSigned}
            >
              <PlusIcon className="-ml-0.5 h-5 w-5 bg-rsblue-800 rounded-full text-white mr-2" />
              Date
            </button>
            <button
              type="button"
              className="inline-flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full mt-2"
              data-element-added="time"
              onClick={handleAddElement}
              disabled={ctx.isSigned}
            >
              <PlusIcon className="-ml-0.5 h-5 w-5 bg-rsblue-800 rounded-full text-white mr-2" />
              Time
            </button>
            <button
              type="button"
              className="inline-flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full mt-2"
              data-element-added="checkbox"
              onClick={handleAddElement}
              disabled={ctx.isSigned}
            >
              <PlusIcon className="-ml-0.5 h-5 w-5 bg-rsblue-800 rounded-full text-white mr-2" />
              Checkbox
            </button>
            <button
              type="button"
              className="inline-flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full mt-2"
              data-element-added="line"
              onClick={handleAddElement}
              disabled={ctx.isSigned}
            >
              <PlusIcon className="-ml-0.5 h-5 w-5 bg-rsblue-800 rounded-full text-white mr-2" />
              Line/Rectangle
            </button>
          </div>
          <hr className="h-px my-4 bg-gray-700 border-0" />
        </>
      )}

      {!ctx.isSigned && (
        <div>
          <h3 className="text-lg font-bold">Signers</h3>

          <button
            type="button"
            className="inline-flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full mt-2"
            onClick={handleAddSelfSigner}
            disabled={ctx.isSigned}
          >
            <PlusIcon className="-ml-0.5 h-5 w-5 bg-rsblue-800 rounded-full text-white mr-2" />
            Self as Signer
          </button>

          <button
            type="button"
            className="inline-flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full mt-2"
            onClick={handleAddSignerOpen}
            disabled={ctx.isSigned}
          >
            <PlusIcon className="-ml-0.5 h-5 w-5 bg-rsblue-800 rounded-full text-white mr-2" />
            Other Signer
          </button>

          <Transition appear show={addSignerIsOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-[200]"
              onClose={() => setAddSignerIsOpen(false)}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black/25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Add Signer
                      </Dialog.Title>
                      <div className="mt-2">
                        <form onSubmit={handleAddSigner}>
                          <div>
                            <label
                              htmlFor="nameInput"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Signer Name
                            </label>
                            <div className="mt-2">
                              <input
                                required
                                type="text"
                                name="name"
                                id="nameInput"
                                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-800 sm:text-sm sm:leading-6"
                                placeholder="Signer Name"
                              />
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="emailInput"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Signer Email
                            </label>
                            <div className="mt-2">
                              <input
                                required
                                type="email"
                                name="email"
                                id="emailInput"
                                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-800 sm:text-sm sm:leading-6"
                                placeholder="signeremail@example.com"
                              />
                            </div>
                          </div>

                          <div className="mt-4">
                            <button
                              type="submit"
                              className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-rsblue-800 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            >
                              Add
                            </button>
                          </div>
                        </form>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>

          {ctx.signers.length > 0 && (
            <>
              <h3 className="text-md font-bold mt-2">Select Signer</h3>
              <select className="w-full p-1.5" onChange={handleSelectedSigner}>
                <option>Select Signer</option>
                {ctx.signers.map((s) => (
                  <option key={`signer_option_${s._id}`} value={s._id}>
                    {s.name} ({s.email})
                  </option>
                ))}
              </select>

              {selectedSigner.length > 0 && (
                <>
                  <SignerElements
                    signers={selectedSigner}
                    styles={props.styles}
                    onRemoveSigner={handleRemoveSigner}
                    setSelectedSigner={setSelectedSigner}
                  />
                </>
              )}

              <hr className="h-px my-4 bg-gray-700 border-0" />
            </>
          )}
        </div>
      )}

      <div>
        <h3 className="text-lg font-bold mt-2">Other Actions</h3>
      </div>
      {ctx.mode === "form" && (
        <button
          type="button"
          className="inline-flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full mt-2"
          data-element-added="text"
          onClick={() => setShowSendFormMessageModal(true)}
        >
          <PaperAirplaneIcon className="-ml-0.5 h-5 w-5 text-rsblue-800 mr-2" />
          Send Form
        </button>
      )}

      <div>
        <button
          type="button"
          className="inline-flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full mt-2"
          onClick={handleToggleHighlights}
        >
          <CursorArrowRaysIcon className="-ml-0.5 h-5 w-5 text-rsblue-800 mr-2" />
          {ctx.highlightFields ? "Remove Highlights" : "Highlight Fields"}
        </button>
      </div>

      <div>
        <button
          type="button"
          className="inline-flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full mt-2"
          onClick={ctx.download}
        >
          <FolderArrowDownIcon className="-ml-0.5 h-5 w-5 text-rsblue-800 mr-2" />
          Download
        </button>
      </div>

      {showSendFormMessageModal && (
        <Transition appear show={showSendFormMessageModal} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-[200]"
            onClose={() => setShowSendFormMessageModal(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Send form for signatures
                    </Dialog.Title>
                    <div className="mt-2">
                      <form onSubmit={handleSendForm}>
                        {ctx.signers.map((s) => (
                          <div key={`recipient_${s._id}`}>
                            <input
                              type="checkbox"
                              name="recipients"
                              value={s._id}
                              id={`recipient_${s._id}`}
                              defaultChecked={true}
                            />{" "}
                            <label htmlFor={`recipient_${s._id}`}>
                              {`${s.name} (${s.email})`}
                            </label>
                          </div>
                        ))}

                        <div>
                          <div className="flex justify-between">
                            <label
                              htmlFor="customMessage"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Message for signers:
                            </label>
                            <button
                              className="text-xs bg-rsblue-600 text-white px-2 py-1 rounded-md"
                              type="button"
                              onClick={handleShowEmailTemplates}
                            >
                              Email Templates
                            </button>
                          </div>

                          <div className="mt-2">
                            <textarea
                              name="customMessage"
                              id="customMessage"
                              className="block w-full h-48 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-rsblue-600 sm:text-sm sm:leading-6"
                              placeholder="Enter a message for your signers..."
                            ></textarea>
                          </div>
                        </div>

                        <div className="mt-4">
                          <button
                            type="submit"
                            className="inline-flex justify-center rounded-md border border-transparent bg-rsblue-600 px-4 py-2 text-sm font-medium text-white hover:bg-rsblue-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          >
                            Send for signatures
                          </button>
                        </div>
                      </form>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}

      {showSelectEmailTemplateModal && (
        <Dialog
          open={showSelectEmailTemplateModal}
          onClose={() => setShowSelectEmailTemplateModal(false)}
          className="relative z-[250]"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          />

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <DialogPanel
                transition
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
              >
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    onClick={() => setShowSelectEmailTemplateModal(false)}
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Select an Email Template
                    </DialogTitle>
                    <div className="mt-2">
                      {ctx.emailTemplates.length > 0 ? (
                        ctx.emailTemplates.map((t) => (
                          <div
                            onClick={() => handleEmailTemplateSelected(t.body)}
                            className="cursor-pointer p-2 border border-gray-300 rounded-md mt-2 hover:border-rsblue-600 hover:border-2"
                          >
                            <div>{t.title}</div>
                            <div>
                              {t.body
                                .split("\n")
                                .map((line) =>
                                  line ? (
                                    <p>{line}</p>
                                  ) : (
                                    <p className="mt-2"></p>
                                  )
                                )}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div>No templates found.</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    onClick={() => setShowSelectEmailTemplateModal(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Back
                  </button>
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      )}

      {ctx.tracking.length > 0 && (
        <div>
          <hr className="h-px my-4 bg-gray-700 border-0" />
          <h3 className="text-lg font-bold mt-2">Tracking</h3>

          <ul className="list-disc ml-5">
            {ctx.tracking.map((t) => (
              <li key={`tracking_${t.signer._id}`} className="text-md">
                {<span title={t.signer.email}>{t.signer.name}</span>}{" "}
                {t.signed ? (
                  <CheckCircleIcon
                    className="fill-green-500 h-4 w-4 inline"
                    title="Signed"
                  />
                ) : t.opened ? (
                  <CheckCircleIcon
                    className="fill-yellow-500 h-4 w-4 inline"
                    title="Opened"
                  />
                ) : t.sent ? (
                  <CheckCircleIcon
                    className="fill-red-500 h-4 w-4 inline"
                    title="Sent"
                  />
                ) : null}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ActionPanel;
