import { createContext, useReducer } from "react";
import toast from "react-hot-toast";

import {
  addDocument,
  deleteDocument,
  uploadPdf,
  getTemplates,
  getDocuments,
  createForm,
  getForm,
  getFormBySid,
  addElement,
  // getElements,
  editElement,
  removeElement,
  addSigner,
  editSigner,
  removeSigner,
  sendFormForSignatures,
  // verifyToken,
  getTrackingForForm,
  downloadForm,
  addEmailTemplate,
  getEmailTemplates,
  updateEmailTemplate,
  deleteEmailTemplate,
} from "../Shared/requests";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
if (!BASE_URL) {
  console.log("No BASE_URL");
  // throw new Error("Must supply base url in REACT_APP_API_BASE_URL variable.");
}

export const FormContext = createContext({
  baseUrl: "",
  mode: "form",
  templates: [],
  documents: [],
  signableDocuments: [],
  signedDocuments: [],
  completedDocuments: [],
  pdf: null,
  pages: null,
  ownerId: null,
  elements: [],
  signers: [],
  tracking: [],
  selectedElement: null,
  signedInUserId: null,
  highlightFields: true,
  hasRequiredElementsMissing: false,
  isSigned: false,
  scale: 1,
  emailTemplates: [],
  // setSignedInUserId: () => {},
  getUser: () => {},
  addDocument: ({ documentName, pdf, mode }) => {},
  deleteDocument: ({ documentId, mode }) => {},
  getTemplatesForUser: () => {},
  getDocumentsForUser: () => {},
  getSignableDocumentsForUser: () => {},
  getSignedDocumentsForUser: () => {},
  getCompletedDocumentsForUser: () => {},
  createFormFromTemplate: ({ templateId }) => {},
  loadInitialForm: () => {},
  getFormBySid: () => {},
  setPdfPages: (numPages) => {},
  addElement: ({ element }) => {},
  updateElement: ({ element, elementId, type }) => {},
  removeElement: ({ elementId }) => {},
  addSigner: ({ signer }) => {},
  updateSigner: ({ signer, signerId }) => {},
  removeSigner: ({ signerId }) => {},
  setSelectedElement: (elementId) => {},
  toggleHighlightFields: () => {},
  sendFormForSignatures: ({ customMessage, recipients }) => {},
  download: () => {},
  loadTracking: () => {},
  setScale: (scale) => {},
  addEmailTemplate: ({ emailTemplateBody, emailTemplateTitle }) => {},
  getEmailTemplates: () => {},
  updateEmailTemplate: ({ id, emailTemplateBody, emailTemplateTitle }) => {},
  deleteEmailTemplate: ({ id }) => {},
});

function formContextReducer(state, action) {
  // console.log("formContextReducer() called", state, action);
  switch (action.type) {
    case "SET_MODE":
      console.log("SET_MODE", action.payload);
      return {
        ...state,
        mode: action.payload,
      };
    case "LOAD_TEMPLATES":
      return {
        ...state,
        templates: action.payload,
      };
    case "LOAD_DOCUMENTS":
      return {
        ...state,
        documents: action.payload,
      };
    case "LOAD_SIGNABLE_DOCUMENTS":
      return {
        ...state,
        signableDocuments: action.payload,
      };
    case "LOAD_SIGNED_DOCUMENTS":
      return {
        ...state,
        signedDocuments: action.payload,
      };
    case "LOAD_COMPLETED_DOCUMENTS":
      return {
        ...state,
        completedDocuments: action.payload,
      };
    case "SET_SIGNED_IN_USERID":
      // console.log("SET_SIGNED_IN_USERID", action.payload);
      return {
        ...state,
        signedInUserId: action.payload,
      };
    case "LOAD_INITIAL_FORM":
      console.log("LOAD_INITIAL_FORM", action.payload);
      const form = action.payload;
      return {
        ...state,
        pdf: form.pdf,
        ownerId: form.ownerId,
        elements: form.elements,
        signers: form.signers,
        signedInUserId: form.signedInUserId,
        hasRequiredElementsMissing: form.elements.some(
          (e) => e.required && !e.value && !e.checked
        ),
        isSigned: form.elements.some(
          (e) => e.tag === "signature" && e.value?.signature
        ),
      };
    case "SET_PDF_PAGES":
      return {
        ...state,
        pages: action.payload,
      };
    case "UPDATE_ELEMENTS":
      // console.log("DISPATCH UPDATE_ELEMENTS");
      return {
        ...state,
        elements: action.payload,
        selectedElement: action.payload.find(
          (e) => e._id === state.selectedElement?._id
        ),
        hasRequiredElementsMissing: action.payload.some(
          (e) => e.required && !e.value && !e.checked
        ),
        isSigned: action.payload.some(
          (e) => e.tag === "signature" && e.value?.signature
        ),
      };
    case "REMOVE_ELEMENT":
      const newElements = state.elements.filter(
        (e) => e._id !== action.payload
      );
      return {
        ...state,
        elements: newElements,
        selectedElement: null,
        hasRequiredElementsMissing: newElements.some(
          (e) => e.required && !e.value && !e.checked
        ),
        isSigned: newElements.some(
          (e) => e.tag === "signature" && e.value?.signature
        ),
      };
    case "UPDATE_SIGNERS":
      // console.log("DISPATCH UPDATE_SIGNERS");
      return {
        ...state,
        signers: action.payload.signers,
        elements: action.payload.elements || state.elements,
      };
    case "REMOVE_SIGNER":
      return {
        ...state,
        signers: action.payload.signers,
        elements: action.payload.elements,
      };
    case "SET_SELECTED_ELEMENT":
      return {
        ...state,
        selectedElement: state.elements.find((e) => e._id === action.payload),
      };
    case "TOGGLE_HIGHLIGHT_FIELDS":
      return {
        ...state,
        highlightFields: !state.highlightFields,
      };
    case "LOAD_TRACKING":
      return {
        ...state,
        tracking: action.payload,
      };
    case "SET_SCALE":
      return {
        ...state,
        scale: action.payload,
      };
    case "LOAD_EMAIL_TEMPLATES":
      return {
        ...state,
        emailTemplates: action.payload || [],
      };
    default:
      return state;
  }
}

export default function FormContextProvider({ children }) {
  const search = new URLSearchParams(window.location.search);
  const modeSearchParam = search.get("mode");
  const modeToSet = modeSearchParam === "template" ? "template" : "form";

  let formId = search.get("formId");

  if (!formId) {
    console.log("No formId");
    formId = search.get("formId");
    // throw new Error("formId must be provided in the query string parameters.");
  }

  const templatesUrl = `${BASE_URL}/templates`;
  const formsUrl = `${BASE_URL}/forms`;
  const formUrl = `${BASE_URL}/forms/${formId}`;
  const signersUrl = `${formUrl}/signers`;
  const elementsUrl = `${formUrl}/elements`;
  const sendForSignaturesUrl = `${formUrl}/send`;
  // const verifyTokenUrl = `${BASE_URL}/verify/token`;
  const verifySidUrl = `${BASE_URL}/verify/sid`;
  const downloadUrl = `${formUrl}/download`;
  const emailTemplatesUrl = `${BASE_URL}/email-templates`;

  const [appState, appStateDispatch] = useReducer(formContextReducer, {
    baseUrl: BASE_URL,
    mode: "form",
    templates: [],
    pdf: null,
    pages: null,
    ownerId: null,
    elements: [],
    signers: [],
    tracking: [],
    selectedElement: null,
    signedInUserId: null,
    highlightFields: true,
    isSigned: false,
    scale: 1,
    emailTemplates: [],
  });

  function handleGetUser() {
    try {
      const tokenParts = localStorage.getItem("id_token").split(".");
      if (tokenParts.length === 3) {
        const user = JSON.parse(atob(tokenParts[1]));
        return user;
      }
    } catch (error) {
      console.log("No user.");
      return null;
    }
  }

  function handleAddDocument({ documentName, pdf, mode }) {
    toast.promise(
      addDocument({
        url: mode === "template" ? templatesUrl : formsUrl,
        token: localStorage.getItem("id_token"),
        body: { documentName },
      }).then(({ presignedUrl }) => {
        return uploadPdf({ url: presignedUrl, body: pdf }).then(
          (successful) => {
            console.log({ successful });
            if (mode === "template") {
              handleGetTemplatesForUser();
            } else {
              handleGetDocumentsForUser();
            }
          }
        );
      }),
      {
        loading: "Adding document...",
        success: "Document added successfully.",
        error: "Error adding document.",
      }
    );
  }

  function handleDeleteDocument({ documentId, mode }) {
    toast.promise(
      deleteDocument({
        url:
          mode === "template"
            ? `${templatesUrl}/${documentId}`
            : `${formsUrl}/${documentId}`,
        token: localStorage.getItem("id_token"),
      }).then(() => {
        if (mode === "template") {
          handleGetTemplatesForUser();
        } else {
          handleGetDocumentsForUser();
          if (window.location.href.toLowerCase().includes("templates")) {
            handleGetTemplatesForUser();
          }
        }
      }),
      {
        loading: `Deleting ${mode === "template" ? "template" : "document"}...`,
        success: `Document ${
          mode === "template" ? "template" : "document"
        } successfully.`,
        error: `Error deleting ${
          mode === "template" ? "template" : "document"
        }. Please try again.`,
      }
    );
  }

  function handleGetTemplatesForUser() {
    getTemplates({
      url: templatesUrl,
      token: localStorage.getItem("id_token"),
    }).then((response) => {
      // console.log(response);
      appStateDispatch({ type: "LOAD_TEMPLATES", payload: response });
    });
  }

  function handleGetDocumentsForUser(limit, skip) {
    getDocuments({
      url: formsUrl,
      token: localStorage.getItem("id_token"),
    }).then((response) => {
      console.log(response);
      appStateDispatch({ type: "LOAD_DOCUMENTS", payload: response });
    });
  }

  function handleGetSignableDocumentsForUser(limit, skip) {
    getDocuments({
      url: `${formsUrl}/signable`,
      token: localStorage.getItem("id_token"),
    }).then((response) => {
      console.log(response);
      appStateDispatch({ type: "LOAD_SIGNABLE_DOCUMENTS", payload: response });
    });
  }

  function handleGetSignedDocumentsForUser(limit, skip) {
    getDocuments({
      url: `${formsUrl}/signed`,
      token: localStorage.getItem("id_token"),
    }).then((response) => {
      console.log(response);
      appStateDispatch({ type: "LOAD_SIGNED_DOCUMENTS", payload: response });
    });
  }

  function handleGetCompletedDocumentsForUser(limit, skip) {
    getDocuments({
      url: `${formsUrl}/completed`,
      token: localStorage.getItem("id_token"),
    }).then((response) => {
      console.log(response);
      appStateDispatch({ type: "LOAD_COMPLETED_DOCUMENTS", payload: response });
    });
  }

  function handleCreateFormFromTemplate({ templateId }) {
    createForm({
      url: `${templatesUrl}/${templateId}/forms`,
      token: localStorage.getItem("id_token"),
    }).then(({ formId }) => {
      console.log(formId);
      window.location.href = `/edit?formId=${formId}`;
    });
  }

  function handleLoadInitialForm() {
    appStateDispatch({ type: "SET_MODE", payload: modeToSet });
    // console.log(search.toString());
    if (search.get("sid")) {
      handleGetFormBySid();
    } else {
      if (search.get("id_token")) {
        localStorage.setItem("id_token", search.get("id_token"));
      }

      toast.promise(
        getForm({
          url: formUrl,
          token: localStorage.getItem("id_token"),
        }).then((form) => {
          console.log(form);
          localStorage.removeItem("fetchingFormInfo");
          appStateDispatch({
            type: "LOAD_INITIAL_FORM",
            payload: form,
          });

          window.history.replaceState(
            null,
            "",
            `${window.location.pathname}?formId=${formId}${
              modeToSet === "template" ? "&mode=template" : ""
            }`
          );
        }),

        {
          loading: "Loading form...",
          success: "Form loaded successfully",
          error: (err) => {
            console.log(err);
            return "Error loading form. Please try again.";
          },
        }
      );
    }
  }

  function handleGetFormBySid() {
    appStateDispatch({ type: "SET_MODE", payload: modeToSet });

    const sid = search.get("sid");
    if (!sid) {
      throw new Error("No SID provided.");
    }
    toast.promise(
      getFormBySid({ url: verifySidUrl, formId, sid }).then((response) => {
        console.log(response);
        localStorage.setItem("id_token", response.token.id_token);
        localStorage.setItem("refresh_token", response.token.refresh_token);
        appStateDispatch({ type: "LOAD_INITIAL_FORM", payload: response.form });
        localStorage.removeItem("fetchingFormInfo");
        window.history.replaceState(
          null,
          "",
          `${window.location.pathname}?formId=${formId}${
            modeToSet === "template" ? "&mode=template" : ""
          }`
        );
      }),
      {
        loading: "Loading form...",
        success: "Form loaded successfully",
        error: (err) => {
          console.log(err);
          return "Error loading form. Please try again.";
        },
      }
    );
  }

  function handleSetPdfPages(pages) {
    appStateDispatch({ type: "SET_PDF_PAGES", payload: pages });
  }

  function handleAddElement({ element }) {
    toast.promise(
      addElement({
        url: `${elementsUrl}`,
        element,
        token: localStorage.getItem("id_token"),
      }).then((response) => {
        appStateDispatch({
          type: "UPDATE_ELEMENTS",
          payload: response.elements,
        });
      }),
      {
        loading: "Adding element...",
        success: `Element added successfully.`,
        error: "Unable to add element.",
      }
    );
  }

  function handleUpdateElement({ element, elementId, type }) {
    console.log("Element update type:", type);
    let url = `${elementsUrl}/${elementId}`;

    if (type === "value") {
      url += "/value";
    } else if (type === "signature") {
      url += "/signature";
    } else if (type === "initials") {
      url += "/value";
    }

    // toast.promise(
    editElement({
      url,
      element,
      token: localStorage.getItem("id_token"),
      updateInfo: {
        isFormOwner: appState.ownerId === appState.signedInUserId,
      },
    })
      .then((response) => {
        appStateDispatch({
          type: "UPDATE_ELEMENTS",
          payload: response.elements,
        });
      })
      .catch((err) => {
        console.log("ERROR:", err);
        toast.error("Unable to update element.");
      });
    // {
    //   loading: "Updating element...",
    //   success: `Element updated successfully.`,
    //   error: "Unable to update element.",
    // }
    // );
  }

  function handleRemoveElement({ elementId }) {
    toast.promise(
      removeElement({
        url: `${elementsUrl}/${elementId}`,
        token: localStorage.getItem("id_token"),
      }).then((response) => {
        appStateDispatch({ type: "REMOVE_ELEMENT", payload: elementId });
      }),
      {
        loading: "Removing element...",
        success: `Element removed successfully.`,
        error: "Unable to remove element.",
      }
    );
  }

  function handleAddSigner({ signer }) {
    toast.promise(
      addSigner({
        url: `${signersUrl}`,
        signer,
        token: localStorage.getItem("id_token"),
      }).then((response) => {
        appStateDispatch({
          type: "UPDATE_SIGNERS",
          payload: { signers: response.signers },
        });
      }),
      {
        loading: "Adding...",
        success: `${signer.name} added as a signer.`,
        error: "Unable to save signer.",
      }
    );
  }

  function handleUpdateSigner({ signer, signerId }) {
    toast.promise(
      editSigner({
        url: `${signersUrl}/${signerId}`,
        signer,
        token: localStorage.getItem("id_token"),
      }).then((response) => {
        appStateDispatch({
          type: "UPDATE_SIGNERS",
          payload: { signers: response.signers, elements: response.elements },
        });
      }),
      {
        loading: "Updating signer...",
        success: "Signer updated successfully.",
        error: "Error updating signer.",
      }
    );
  }

  function handleRemoveSigner({ signerId }) {
    console.log("###############", signerId);
    toast.promise(
      removeSigner({
        url: `${signersUrl}/${signerId}`,
        token: localStorage.getItem("id_token"),
      }).then((response) => {
        console.log(response);
        appStateDispatch({
          type: "REMOVE_SIGNER",
          payload: { signers: response.signers, elements: response.elements },
        });
      }),
      {
        loading: "Removing signer...",
        success: "Signer removed.",
        error: "Unable to remove signer",
      }
    );
  }

  function handleSetSelectedElement(elementId) {
    appStateDispatch({ type: "SET_SELECTED_ELEMENT", payload: elementId });
  }

  function handleToggleHighlightFields() {
    appStateDispatch({ type: "TOGGLE_HIGHLIGHT_FIELDS" });
  }

  function handleSendFormForSignatures({ customMessage, recipients }) {
    toast.promise(
      sendFormForSignatures({
        url: sendForSignaturesUrl,
        token: localStorage.getItem("id_token"),
        customMessage,
        recipients,
      }).then((response) => {
        console.log("form sent", response);
      }),
      {
        loading: "Sending document for signatures...",
        success: "Sent for signatures successfully.",
        error: "Error sending document for signatures.",
      }
    );
  }

  function handleDownloadForm() {
    toast.promise(
      downloadForm({
        url: downloadUrl,
        token: localStorage.getItem("id_token"),
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${formId}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }),
      {
        loading: "Downloading document...",
        success: "Document downloaded.",
        error: "Error downloading document.",
      }
    );
  }

  function handleLoadTracking() {
    getTrackingForForm({
      url: `${BASE_URL}/tracking/${formId}`,
      token: localStorage.getItem("id_token"),
    }).then((response) => {
      console.log("tracking:", response);
      appStateDispatch({ type: "LOAD_TRACKING", payload: response });
    });
  }

  function handleSetScale(scale) {
    appStateDispatch({ type: "SET_SCALE", payload: scale });
  }

  function handleAddEmailTemplate({ emailTemplateBody, emailTemplateTitle }) {
    toast.promise(
      addEmailTemplate({
        url: emailTemplatesUrl,
        token: localStorage.getItem("id_token"),
        emailTemplateBody,
        emailTemplateTitle,
      }).then(() => {
        handleGetEmailTemplates();
      }),
      {
        loading: "Adding email template...",
        success: "Email template added.",
        error: "Error adding email template.",
      }
    );
  }

  function handleGetEmailTemplates() {
    getEmailTemplates({
      url: emailTemplatesUrl,
      token: localStorage.getItem("id_token"),
    }).then((response) => {
      appStateDispatch({ type: "LOAD_EMAIL_TEMPLATES", payload: response });
    });
  }

  function handleUpdateEmailTemplate({
    id,
    emailTemplateBody,
    emailTemplateTitle,
  }) {
    toast.promise(
      updateEmailTemplate({
        url: `${emailTemplatesUrl}/${id}`,
        token: localStorage.getItem("id_token"),
        emailTemplateBody,
        emailTemplateTitle,
      }).then(() => {
        handleGetEmailTemplates();
      }),
      {
        loading: "Updating email template...",
        success: "Email template updated.",
        error: "Error updating email template.",
      }
    );
  }

  function handleDeleteEmailTemplate({ id }) {
    toast.promise(
      deleteEmailTemplate({
        url: `${emailTemplatesUrl}/${id}`,
        token: localStorage.getItem("id_token"),
      }).then(() => {
        handleGetEmailTemplates();
      }),
      {
        loading: "Deleting email template...",
        success: "Email template deleted.",
        error: "Error deleting email template.",
      }
    );
  }

  const appCtx = {
    baseUrl: appState?.baseUrl,
    mode: appState?.mode,
    templates: appState?.templates,
    documents: appState?.documents,
    signableDocuments: appState?.signableDocuments || [],
    signedDocuments: appState?.signedDocuments || [],
    completedDocuments: appState?.completedDocuments || [],
    pdf: appState?.pdf,
    pages: appState?.pages,
    ownerId: appState?.ownerId,
    elements: appState?.elements,
    signers: appState?.signers,
    tracking: appState?.tracking,
    selectedElement: appState?.selectedElement,
    signedInUserId: appState?.signedInUserId,
    highlightFields: appState?.highlightFields,
    isSigned: appState?.isSigned,
    hasRequiredElementsMissing: appState?.hasRequiredElementsMissing,
    scale: appState?.scale,
    emailTemplates: appState?.emailTemplates,
    // setSignedInUserId: handleSetSignedInUserId,
    getUser: handleGetUser,
    addDocument: handleAddDocument,
    deleteDocument: handleDeleteDocument,
    getTemplatesForUser: handleGetTemplatesForUser,
    getDocumentsForUser: handleGetDocumentsForUser,
    getSignableDocumentsForUser: handleGetSignableDocumentsForUser,
    getSignedDocumentsForUser: handleGetSignedDocumentsForUser,
    getCompletedDocumentsForUser: handleGetCompletedDocumentsForUser,
    createFormFromTemplate: handleCreateFormFromTemplate,
    loadInitialForm: handleLoadInitialForm,
    getFormBySid: handleGetFormBySid,
    setPdfPages: handleSetPdfPages,
    addElement: handleAddElement,
    updateElement: handleUpdateElement,
    removeElement: handleRemoveElement,
    addSigner: handleAddSigner,
    updateSigner: handleUpdateSigner,
    removeSigner: handleRemoveSigner,
    setSelectedElement: handleSetSelectedElement,
    toggleHighlightFields: handleToggleHighlightFields,
    sendFormForSignatures: handleSendFormForSignatures,
    download: handleDownloadForm,
    loadTracking: handleLoadTracking,
    setScale: handleSetScale,
    addEmailTemplate: handleAddEmailTemplate,
    getEmailTemplates: handleGetEmailTemplates,
    updateEmailTemplate: handleUpdateEmailTemplate,
    deleteEmailTemplate: handleDeleteEmailTemplate,
  };

  return <FormContext.Provider value={appCtx}>{children}</FormContext.Provider>;
}
