import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect } from "react";

import "./assets/main.css";
import { Toaster } from "react-hot-toast";

import FormContextProvider from "./components/Contexts/FormContext";

import Header from "./components/Shared/UI/Header";
// import Home from "./components/Home/Sidebar";

import PdfEditor from "./components/PdfEditor/PdfEditor";
import PdfSigner from "./components/PdfSigner/PdfSigner";
import VerifyPage from "./components/Verify/verify";

import Dashboard from "./components/Home/Dashboard";
import Templates from "./components/Home/Templates";
import Documents from "./components/Home/Documents";
import Reports from "./components/Home/Reports";

import Login from "./components/Auth/Login";
import Logout from "./components/Auth/Logout";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ResetPassword from "./components/Auth/ResetPassword";

import Terms from "./components/Shared/terms";
import Privacy from "./components/Shared/privacy";

import SignableDocuments from "./components/Home/SignableDocuments";
import SignedDocuments from "./components/Home/SignedDocuments";
import CompletedDocuments from "./components/Home/CompletedDocuments";

import EmailTemplates from "./components/Home/EmailTemplates";


import FloatingHelpButton from "./components/Shared/Help/FloatingHelpButton";

if (
  window.location.host !== "localhost:3000" &&
  window.location.host !== "resure-sign.com"
) {
  let url = new URL(window.location);
  window.location.href = `https://resure-sign.com${url.pathname}${url.search}`;
}

function removeLoadingStatus() {
  localStorage.removeItem("fetchingFormInfo");
  localStorage.removeItem("lat-long-check");
}

function App() {
  useEffect(() => {
    console.log("trying to get location");

    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log(position);
      },
      (_) => {
        console.log("Geo location not available.");
        if (!localStorage.getItem("geo-needed-notification")) {
          alert("Please enable location access for signature verification.");
          localStorage.setItem("geo-needed-notification", "true");
        }
        localStorage.setItem("lat-long-check", "failed");
      },
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
    );

    window.addEventListener("unload", removeLoadingStatus);
    return () => {
      window.removeEventListener("unload", removeLoadingStatus);
    };
  });

  return (
    <>
      <Toaster position="top-center" />
      <FloatingHelpButton />
      <FormContextProvider>
        <BrowserRouter>
          {/* <Header />  */}
          <Routes>
            <Route
              path="/"
              element={
                localStorage.getItem("id_token") ? (
                  <Dashboard />
                ) : (
                  <>
                    <div>Marketing Page </div>
                    <a href="/login">Login</a>
                  </>
                )
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />

            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            <Route path="/documents" element={<Documents />} />
            <Route path="/templates" element={<Templates />} />
            <Route path="/templates/:templateId" element={<Templates />} />
            <Route path="/reports" element={<Reports />} />

            <Route path="/documents/signable" element={<SignableDocuments />} />
            <Route path="/documents/signed" element={<SignedDocuments />} />
            <Route path="/documents/completed" element={<CompletedDocuments />} />

            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />

              <Route path="/email-templates" element={<EmailTemplates />} />

            <Route
              path="/signlink"
              element={<Navigate to={`/sign${window.location.search}`} />}
            />

            <Route
              path="/edit"
              element={
                <>
                  <Header />
                  <PdfEditor />
                </>
              }
            />
            <Route
              path="/sign"
              element={
                <>
                  <Header />
                  <PdfSigner />
                </>
              }
            />

            <Route path="/verify/:formId" element={<VerifyPage />} />
          </Routes>
        </BrowserRouter>
      </FormContextProvider>
    </>
  );
}

export default App;
