import { useContext, useEffect, useState } from "react";
import {
  CheckCircleIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  TrashIcon,
  EnvelopeIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/solid";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";

import Sidebar from "./Sidebar";

import ConfirmDelete from "../Shared/UI/ConfirmDelete";
import { FormContext } from "../Contexts/FormContext";

import EmailTemplateModal from "./EmailTemplateModal";

export default function Documents() {
  const ctx = useContext(FormContext);

  const [showAddEmailTemplateModal, setShowAddEmailTemplateModal] =
    useState(false);
  const [showEditEmailTemplateModal, setShowEditEmailTemplateModal] =
    useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);
  const [editEmailTemplateModal, setEditEmailTemplateModal] = useState(null);

  function toggleShowAddEmailTemplateModal() {
    setShowAddEmailTemplateModal((prevValue) => !prevValue);
  }

  function handleDeleteClick({ id }) {
    setDeleteModal(
      <ConfirmDelete
        title="Confirm Deletion"
        message={`Are you sure you want to delete this email template? This action cannot be undone.`}
        onSuccess={() => {
          ctx.deleteEmailTemplate({ id });
          setShowConfirmDeleteModal(false);
          setDeleteModal(null);
        }}
        onCancel={() => {
          setShowConfirmDeleteModal(false);
          setDeleteModal(null);
        }}
      />
    );

    setShowConfirmDeleteModal(true);
  }

  function handleAddEmailTemplate(event) {
    console.log("Adding email template", event);

    const title = document.getElementById("emailTemplateTitle").value;
    const body = document.getElementById("emailTemplateBody").value;

    console.log({ title, body });

    ctx.addEmailTemplate({
      emailTemplateTitle: title,
      emailTemplateBody: body,
    });
    setShowAddEmailTemplateModal(false);
  }

  function handleEditEmailTemplate(id) {
    const emailTemplateTitle =
      document.getElementById("emailTemplateTitle").value;
    const emailTemplateBody =
      document.getElementById("emailTemplateBody").value;

    ctx.updateEmailTemplate({
      id,
      emailTemplateBody,
      emailTemplateTitle,
    });

    setShowEditEmailTemplateModal(false);
    setEditEmailTemplateModal(null);
  }

  function handleEditClick(template) {
    // console.log("Editing email template", template);
    setEditEmailTemplateModal(
      <EmailTemplateModal
        onClose={() => {
          setEditEmailTemplateModal(null);
          setShowEditEmailTemplateModal(false);
        }}
        modalTitle="Edit Email Template"
        emailTemplateTitle={template.title}
        emailTemplateBody={template.body}
        saveButtonLabel="Save"
        onSave={() => handleEditEmailTemplate(template._id)}
      />
    );

    setShowEditEmailTemplateModal(true);
  }

  useEffect(() => {
    ctx.getEmailTemplates();
  }, []);

  return (
    <>
      {showAddEmailTemplateModal && (
        <EmailTemplateModal
          onClose={() => setShowAddEmailTemplateModal(false)}
          modalTitle="Add Email Template"
          emailTemplateTitle=""
          emailTemplateBody=""
          saveButtonLabel="Add"
          onSave={handleAddEmailTemplate}
        />
      )}

      {showEditEmailTemplateModal && editEmailTemplateModal}

      {showConfirmDeleteModal && deleteModal}

      <Sidebar activeNav="Email Templates" />
      <main className="py-6 lg:py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          {/* Your content */}
          <h1 className="font-bold text-xl">
            Email Templates{" "}
            <button
              type="button"
              onClick={toggleShowAddEmailTemplateModal}
              className="text-lg font-normal"
            >
              (Add <PlusCircleIcon className="h-5 w-5 inline" />)
            </button>
          </h1>

          <div className="flex flex-wrap gap-4">
            {
              // Display email templates
              ctx.emailTemplates.map((template) => (
                <div
                  key={template._id}
                  className="bg-white shadow-sm rounded-md p-4 w-full xl:w-1/2"
                >
                  <div className="flex justify-between">
                    <h2 className="font-semibold text-lg">{template.title}</h2>
                    <div className="flex gap-4">
                      <button
                        type="button"
                        onClick={() => {
                          handleEditClick(template);
                        }}
                      >
                        <PencilSquareIcon className="h-5 w-5" />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          handleDeleteClick({ id: template._id });
                        }}
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                  <div>{template.body.split("\n").map(line => (<>{line} <br /> </>))}</div>
                </div>
              ))
            }
          </div>
        </div>
      </main>
    </>
  );
}
