import React from "react";
import Header from "./UI/Header";
import TermsPrivacyFooter from "./UI/TermsPrivacyFooter";

const Privacy = () => {
  return (
    <>
      <Header />
      <div className="pt-6 pb-20 px-10 sm:px0">
        <div className="relative mx-auto max-w-[37.5rem] text-center pb-10">
          <img
            src="/images/resure-sign-logo-black.png"
            alt="RE-Sure Sign Logo"
            className="w-2/3 mx-auto"
          />
          <h1 className="text-2xl font-extrabold tracking-tight text-slate-900 sm:text-3xl mt-4">
            Privacy Policy
          </h1>
          <p className="mt-4 text-base leading-7 text-slate-600">
            Last Updated: February 1, 2024
          </p>
        </div>

        <div className="mx-auto max-w-[40rem] prose-sm prose prose-slate prose-a:font-semibold prose-a:text-sky-500 hover:prose-a:text-sky-600">
          <p>
            Welcome to RE-Sure Sign ("we," "us," or "our"). This Privacy Policy
            outlines how we collect, use, and protect your personal information.
            By using our platform, you agree to the terms of this Privacy
            Policy.
          </p>

          <h2>1. Information We Collect</h2>

          <p>
            a. User Account Information: To use RE-Sure Sign, you may need to
            create an account. We collect information such as your name, email
            address, password, and third party IDs.
          </p>

          <p>
            b. Document Information: When you use our document signing services,
            we collect and store the documents you upload to the platform.
          </p>

          <p>
            c. Usage Information: We collect information about your interactions
            with the platform, including log files, IP addresses, and device
            information.
          </p>

          <h2>2. How We Use Your Information</h2>

          <p>
            a. Providing Services: We use your information to provide the
            document signing services, authenticate users, and ensure the
            security of our platform.
          </p>

          <p>
            b. Communication: We may use your email address to send you
            important information about your account, updates, or promotional
            materials.
          </p>

          <p>
            c. Improving Services: We use aggregated and anonymized data to
            analyze and improve our platform's functionality and user
            experience.
          </p>

          <h2>3. Data Security</h2>

          <p>
            a. Security Measures: We implement industry-standard security
            measures to protect your personal information. However, no method of
            transmission over the internet or electronic storage is 100% secure.
          </p>

          <p>
            b. Document Storage: While we take measures to protect your
            documents, users are responsible for ensuring the security and
            confidentiality of sensitive information.
          </p>

          <h2>4. Third-Party Services</h2>

          <p>
            a. Integration Partners: We may integrate with third-party services
            to enhance our platform's functionality. Your use of such services
            is subject to their respective privacy policies.
          </p>

          <p>
            b. Analytics: We use third-party analytics services to track and
            analyze user interactions. These services may use cookies and other
            tracking technologies.
          </p>

          <h2>5. Sharing of Information</h2>

          <p>
            a. Legal Compliance: We may disclose information in response to a
            legal request or to comply with applicable laws and regulations.
          </p>

          <p>
            b. Business Transactions: In the event of a merger, acquisition, or
            sale of assets, your information may be transferred to a third
            party.
          </p>

          <h2>6. Your Choices</h2>

          <p>
            a. Account Settings: You can review and update your account
            information through the platform's settings.
          </p>

          <p>
            b. Communication Preferences: You can opt-out of receiving
            promotional emails by following the instructions in the emails.
          </p>

          <h2>7. Children's Privacy</h2>

          <p>
            Our platform is not intended for children under the age of 13. We do
            not knowingly collect or solicit personal information from children.
          </p>

          <h2>8. Changes to this Privacy Policy</h2>

          <p>
            We reserve the right to update or change this Privacy Policy at any
            time. Any changes will be effective immediately upon posting the
            updated policy on the platform.
          </p>

          <h2>9. Contact Information</h2>

          <p>
            If you have any questions about this Privacy Policy, please contact
            us at support@resure-sign.com.
          </p>

          <p>
            By using RE-Sure Sign, you acknowledge that you have read,
            understood, and agreed to this Privacy Policy.
          </p>
        </div>
      </div>
      <TermsPrivacyFooter />
    </>
  );
};

export default Privacy;
