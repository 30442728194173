import { useContext } from "react";
import { FormContext } from "../../Contexts/FormContext";

import { PlusIcon } from "@heroicons/react/20/solid";

const SignerElements = ({
  signers,
  styles,
  onRemoveSigner,
  setSelectedSigner,
}) => {
  const ctx = useContext(FormContext);

  const handleSignerChanged = (event) => {
    if (event.target.dataset?.dirty === "yes") {
      const signerId = event.target.dataset?.signerId;
      const key = event.target.dataset?.attributeName;
      const toUpdate = {};
      toUpdate[key] = event.target.value;
      event.target.dataset.dirty = "no";
      ctx.updateSigner({ signer: toUpdate, signerId });
      if (key === "email") {
        setSelectedSigner([]);
      }
    }
  };

  const handleAddSignerElement = (event) => {
    let target = event.target;

    if (!target.dataset?.elementAdded) {
      target = event.target.parentNode;
    }

    const signerId = target.dataset.signerId;
    const elementAdded = target.dataset.elementAdded;

    const newElement = {
      page: 1,
      top: window.scrollY + window.innerHeight / 2,
      left: 306,
      signerId: signerId,
    };

    switch (elementAdded) {
      case "signer-signature":
        newElement.tag = "signature";
        newElement.isDraggable = true;
        newElement.isResizable = true;
        newElement.width = 146;
        newElement.height = 28;
        break;
      case "signer-name":
        newElement.tag = "signer-name";
        newElement.isDraggable = true;
        newElement.isResizable = true;
        newElement.width = 150;
        newElement.value = ctx.signers.find((s) => s._id === signerId).name;
        newElement.fontSize = 10;
        break;
      case "signer-email":
        newElement.tag = "signer-email";
        newElement.isDraggable = true;
        newElement.isResizable = true;
        newElement.width = 150;
        newElement.value = ctx.signers.find((s) => s._id === signerId).email;
        newElement.fontSize = 10;
        break;
      case "signer-signature-date":
        newElement.tag = "signature-date";
        newElement.isDraggable = true;
        newElement.isResizable = true;
        newElement.width = 90;
        newElement.fontSize = 10;
        newElement.value = "Signature Date";
        break;
      case "signer-initials":
        newElement.tag = "signer-initials";
        newElement.isDraggable = true;
        newElement.isResizable = true;
        newElement.width = 30;
        newElement.height = 20;
        break;
      default:
        break;
    }

    ctx.addElement({ element: newElement });
  };

  const handleRemoveSigner = (event) => {
    ctx.removeSigner({ signerId: event.target.dataset?.signerId });
    onRemoveSigner();
  };

  const makeDirty = (event) => {
    event.target.dataset.dirty = "yes";
  };

  return signers.map((s) => {
    return (
      <div
        key={`signer_block_${s._id}`}
        id={`signer_block_${s._id}`}
        className="mt-2"
      >
        <div
          data-signer-id={s._id}
          className={styles["delete-signer"]}
          onClick={handleRemoveSigner}
        ></div>

        <div>
          <label htmlFor={`signer_name_${s._id}`} className="text-sm">
            Name
          </label>
          <input
            data-signer-id={s._id}
            data-attribute-name="name"
            className="w-full p-1.5"
            type="text"
            id={`signer_name_${s._id}`}
            name={`signer_name_${s._id}`}
            defaultValue={s.name}
            onBlur={handleSignerChanged}
            onInput={makeDirty}
          />
        </div>
        <div>
          <label htmlFor={`signer_email_${s._id}`} className="text-sm">
            Email
          </label>
          <input
            data-signer-id={s._id}
            data-attribute-name="email"
            className="w-full p-1.5"
            type="email"
            id={`signer_email_${s._id}`}
            name={`signer_email_${s._id}`}
            defaultValue={s.email}
            onBlur={handleSignerChanged}
            onInput={makeDirty}
          />
        </div>

        <button
          data-signer-id={s._id}
          data-element-added="signer-signature"
          type="button"
          className="inline-flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full mt-2"
          onClick={handleAddSignerElement}
          disabled={ctx.isSigned}
        >
          <PlusIcon className="-ml-0.5 h-5 w-5 bg-rsblue-800 rounded-full text-white mr-2" />
          Signature Panel
        </button>

        <button
          data-signer-id={s._id}
          data-element-added="signer-name"
          type="button"
          className="inline-flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full mt-2"
          onClick={handleAddSignerElement}
        >
          <PlusIcon className="-ml-0.5 h-5 w-5 bg-rsblue-800 rounded-full text-white mr-2" />
          Signer Name
        </button>

        <button
          data-signer-id={s._id}
          data-element-added="signer-email"
          type="button"
          className="inline-flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full mt-2"
          onClick={handleAddSignerElement}
        >
          <PlusIcon className="-ml-0.5 h-5 w-5 bg-rsblue-800 rounded-full text-white mr-2" />
          Signer Email
        </button>

        <button
          data-signer-id={s._id}
          data-element-added="signer-signature-date"
          type="button"
          className="inline-flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full mt-2"
          onClick={handleAddSignerElement}
        >
          <PlusIcon className="-ml-0.5 h-5 w-5 bg-rsblue-800 rounded-full text-white mr-2" />
          Signature Date
        </button>
        <button
          data-signer-id={s._id}
          data-element-added="signer-initials"
          type="button"
          className="inline-flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full mt-2"
          onClick={handleAddSignerElement}
        >
          <PlusIcon className="-ml-0.5 h-5 w-5 bg-rsblue-800 rounded-full text-white mr-2" />
          Signer Intials
        </button>
      </div>
    );
  });
};

export default SignerElements;
