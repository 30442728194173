import { Page } from "react-pdf";

import FormElements from "./FormElements";
import { useContext } from "react";
import { FormContext } from "../../Contexts/FormContext";

const PageWrapper = (props) => {
  const ctx = useContext(FormContext);

  return Array.from({ length: ctx.pages }, (v, i) => i + 1).map((page) => (
    <div
      key={`page_${page}_wrapper`}
      id={`page_${page}_wrapper`}
      // className={`${props.styles["page-wrapper"]} ${props.styles["droppable"]}`}
      className="page-wrapper droppable  mb-2.5 bg-[#efefef] border-solid border-2 border-rsblue-900" // border-solid border-2 border-blue-800
      data-page-number={page}
    >
      <FormElements
        key={`page_${page}_annotations`}
        page={page}
        styles={props.styles}
      />
      <Page
        key={`page_${page}`}
        pageNumber={page}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        scale={ctx.scale}
      />
    </div>
  ));
};

export default PageWrapper;
