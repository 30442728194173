import { useContext, useEffect, useState } from "react";
import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/24/solid";

import Sidebar from "./Sidebar";

import { FormContext } from "../Contexts/FormContext";

import { downloadForm } from "../Shared/requests";

export default function CompletedDocuments() {
  const ctx = useContext(FormContext);

  function download(formId) {
    const url = `${ctx.baseUrl}/forms/${formId}/download`;

    downloadForm({
      url,
      token: localStorage.getItem("id_token"),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${formId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  }

  useEffect(() => {
    ctx.getCompletedDocumentsForUser();
  }, []);

  return (
    <>
      <Sidebar activeNav="Completed" />
      <main className="py-6 lg:py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          {/* Your content */}
          <h1 className="font-bold text-xl">Completed Documents</h1>

          <div className="flex flex-wrap gap-4">
            {ctx.completedDocuments.length > 0 ? (
              ctx.completedDocuments?.map((f) => {
                return (
                  <div
                    key={f._id}
                    className="mt-2 bg-slate-100 p-2 rounded-md hover:bg-blue-100 w-full md:w-[calc(50%-.5rem)] lg:w-[calc(33%-1rem)] flex flex-col justify-between"
                  >
                    <div>
                      <h2 className="text-lg font-bold">{f.formName}</h2>
                      <div>Requested by: {f.ownerName}</div>
                      <div>
                        Signers:{" "}
                        <ul>
                          {f.signers.map((s) => {
                            return (
                              <li
                                className="pl-5 list-disc list-inside"
                                key={`${f._id}_${s._id}`}
                              >
                                {s.name}{" "}
                                {f.tracking.some(
                                  (t) =>
                                    t.signerId === s._id && t.signed?.length > 0
                                ) ? (
                                  <CheckCircleIcon className="fill-green-500 h-4 w-4 inline" />
                                ) : f.tracking.some(
                                    (t) =>
                                      t.signerId === s._id &&
                                      t.opened?.length > 0
                                  ) ? (
                                  <MinusCircleIcon className="fill-yellow-500 h-4 w-4 inline" />
                                ) : f.tracking.some(
                                    (t) =>
                                      t.signerId === s._id && t.sent?.length > 0
                                  ) ? (
                                  <MinusCircleIcon className="fill-red-500 h-4 w-4 inline" />
                                ) : null}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div>
                        Created: {new Date(f.createdAt).toLocaleString()}
                      </div>
                      <div>
                        {f.tracking.some((t) => t.completed)
                          ? `Completed: ${new Date(
                              f.tracking[0].completed
                            ).toLocaleString()}`
                          : `Updated: ${new Date(
                              f.updatedAt
                            ).toLocaleString()}`}{" "}
                      </div>
                    </div>
                    <div className="mt-1 flex justify-between">
                      <div>
                        <a
                          href={`/sign?formId=${f._id}`}
                          className="rounded-md bg-blue-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                        >
                          Open
                        </a>
                        <button
                          type="button"
                          className="ml-2 rounded-md bg-blue-500 px-2.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                          onClick={() => download(f._id)}
                        >
                          Download
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="mt-2">No completed documents.</div>
            )}
          </div>
        </div>
      </main>
    </>
  );
}
