import React from "react";
import { Link } from "react-router-dom";
import Header from "./UI/Header";
import TermsPrivacyFooter from "./UI/TermsPrivacyFooter";

const Terms = () => {
  return (
    <>
      <Header />
      <div className="pt-6 pb-20 px-10 sm:px0">
        <div className="relative mx-auto max-w-[37.5rem] text-center pb-10">
          <img
            src="/images/resure-sign-logo-black.png"
            alt="RE-Sure Sign Logo"
            className="w-2/3 mx-auto"
          />
          <h1 className="text-2xl font-extrabold tracking-tight text-slate-900 sm:text-3xl mt-4">
            Terms and Conditions
          </h1>
          <p className="mt-4 text-base leading-7 text-slate-600">
            Last Updated: February 1, 2024
          </p>
        </div>

        <div className="mx-auto max-w-[40rem] prose-sm prose prose-slate prose-a:font-semibold prose-a:text-sky-500 hover:prose-a:text-sky-600">
          <p>
            Welcome to RE-Sure Sign ("we," "us," or "our"). These Terms of
            Service ("Terms") govern your use of our platform and services, so
            please read them carefully. By accessing or using our platform, you
            agree to comply with these Terms. If you do not agree with these
            Terms, please do not use our platform.
          </p>

          <h2>1. Acceptance of Terms</h2>

          <p>
            By using RE-Sure Sign, you agree to be bound by these Terms and any
            future modifications. We reserve the right to update or change these
            Terms at any time, and you are responsible for reviewing them
            periodically. Your continued use of the platform after any changes
            indicates your acceptance of those changes.
          </p>

          <h2>2. User Accounts</h2>

          <p>
            a. Account Registration: To use certain features of our platform,
            you may be required to create an account. You agree to provide
            accurate and complete information during the registration process.
          </p>

          <p>
            b. Account Security: You are responsible for maintaining the
            security of your account credentials. Notify us immediately if you
            become aware of any unauthorized use of your account.
          </p>

          <p>3. Use of the Platform</p>

          <p>
            a. Authorized Use: You agree to use RE-Sure Sign for its intended
            purpose and in compliance with all applicable laws and regulations.
          </p>

          <p>
            b. Prohibited Conduct: You must not engage in any conduct that may
            disrupt the functionality of the platform or compromise its
            security. Prohibited conduct includes, but is not limited to,
            unauthorized access, data scraping, or any activity that violates
            the rights of others.
          </p>

          <h2>4. Document Signing Services</h2>

          <p>
            a. Electronic Signatures: RE-Sure Sign facilitates the electronic
            signing of documents. Users are responsible for ensuring the
            legality and enforceability of electronic signatures in their
            jurisdiction.
          </p>

          <p>
            b. Document Ownership: Users retain ownership of their documents. We
            do not claim ownership but may use and store documents as necessary
            to provide our services.
          </p>

          <h2>5. Privacy and Data Security</h2>

          <p>
            a. Privacy Policy: Our Privacy Policy outlines how we collect, use,
            and protect your personal information. By using our platform, you
            agree to our{" "}
            <Link to="/privacy" reloadDocument>
              Privacy Policy
            </Link>
            .
          </p>

          <p>
            b. Data Security: We implement reasonable security measures to
            protect your data. However, we cannot guarantee the security of
            information transmitted through the internet.
          </p>

          <h2>6. Fees and Payment</h2>

          <p>
            a. Fees: Some features of RE-Sure Sign may require payment. You
            agree to pay all fees associated with your use of these features.
          </p>

          <p>
            b. Payment Information: By providing payment information, you
            represent and warrant that you have the right to use the payment
            method.
          </p>

          <h2>7. Termination</h2>

          <p>
            We reserve the right to terminate or suspend your account, with or
            without cause, at any time. You may terminate your account by
            contacting us.
          </p>

          <h2>8. Limitation of Liability</h2>

          <p>
            To the extent permitted by law, RE-Sure Sign is not liable for any
            indirect, incidental, special, consequential, or punitive damages,
            or any loss of profits or revenues.
          </p>

          <h2>9. Governing Law</h2>

          <p>
            These Terms are governed by and construed in accordance with the
            laws of Colorado.
          </p>

          <h2>10. Contact Information</h2>

          <p>
            If you have any questions about these Terms, please contact us at
            support@resure-sign.com.
          </p>

          <p>
            By using RE-Sure Sign, you acknowledge that you have read,
            understood, and agreed to these Terms of Service.
          </p>
        </div>
      </div>
        <TermsPrivacyFooter />
    </>
  );
};

export default Terms;
