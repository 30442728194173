import { useContext, useEffect } from "react";
import { pdfjs, Document } from "react-pdf";
import PageWrapper from "../PdfEditor/components/PageWrapper";
import { FormContext } from "../Contexts/FormContext";

import ActionPanelLite from "./ActionPanelLite";
import SignButton from "../PdfEditor/components/SignButton";

import styles from "./PdfSigner.module.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfSign = () => {
  const ctx = useContext(FormContext);

  useEffect(() => {
    if (!localStorage.getItem("fetchingFormInfo")) {
      localStorage.setItem("fetchingFormInfo", "true");
      ctx.loadInitialForm();
    }
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    ctx.setPdfPages(numPages);
  };

  return (
    // <div id={styles["app-wrapper"]} className="bg-[#efefef]">
    <div className="bg-[#efefef] flex">
      <ActionPanelLite styles={styles} />
      <div id={styles["pdf-viewer-sign"]}>
        {ctx.pages > 1 && <SignButton styles={styles} />}

        <Document file={ctx.pdf} onLoadSuccess={onDocumentLoadSuccess}>
          <PageWrapper pages={ctx.pages} styles={styles} />
        </Document>
      </div>
    </div>
  );
};

export default PdfSign;
