import React from 'react';

const TermsPrivacyFooter = () => {
    return (
        <div className="fixed bottom-0 left-0 w-full bg-gray-200 p-4">
            <div className="flex justify-center">
                <a href="/terms" className="mr-4 text-sm">Terms of Service</a>
                <a href="/privacy" className='text-sm'>Privacy Policy</a>
            </div>
        </div>
    );
};

export default TermsPrivacyFooter;
